import React, { useState } from "react";
import "./AlertPopup.scss";
import Modal from "components/shared/Modal";
import Info from "assets/images/info-outline.svg";
import CrossIcon from "assets/images/cross-large.svg";
import { removeFromLocalStorage } from 'utils/localStorage/localStorage';
import { globalAdobeAnalytics } from '../../../analytics.js';
import { MailButton } from "../MailButton";

export const AlertPopup = ({ open, type, message, otherData, requestId, requestType, projectType, errorMsg, isNewRequest, closeAlertHandler }) => {
    const [openModel, setOpenModel] = useState(type ? open : false);

    const tryAgainButtonClick = () => {
        setOpenModel(false);
        closeAlertHandler();
    }

    const successAlertButtonClick = () => {
        removeFromLocalStorage('hasDetailsLoaded');
        globalAdobeAnalytics('EEH Home Page', 'EEH Request Form');
        sessionStorage.clear('hasLoaded');
        if (requestType == 'Survey') {
            window.location.href = '/';
        } else {
            window.location.href = `/requestDetails?interviewId=${requestId}`;
        }
    }

    return (
        <Modal isOpen={openModel} className="alert__modal">
            {type == 'error' && <img className="close-icon" src={CrossIcon} alt="close" onClick={() => tryAgainButtonClick()} />}
            <div className={`${type == 'simple' ? "alert__simple-wrapper" : "alert__wrapper"}`}>
                {type == 'simple' ?
                    <>
                        <div className="alert__heading">
                            <span>{message}</span>
                        </div>
                        <div className="alert__action-container">
                            <button className="outline" s onClick={() => closeAlertHandler(false, otherData)}>No, Cancel</button>
                            <button className="primary" onClick={() => closeAlertHandler(true, otherData)}>Yes, Remove</button>
                        </div>
                    </>
                    :
                    <>
                        <div className="alert__heading">
                            {type == 'success' ? <span>Your {requestType} Request - ID - {requestId} has been {isNewRequest ? 'successfully submitted.' : 'updated successfully.'}</span>
                                : <span>Request Submission Failed.</span>}
                        </div>
                        {requestType == 'Survey' ?
                            <div className="alert__details">
                                {type == 'success' ? <p>Please note that your survey request will be addressed by selected providers via email and managed offline for the duration of EEH trial.</p>
                                    : <p className="error-text">{errorMsg ? errorMsg : "Your request couldn&lsquo;t be submitted due to a technical glitch. We apologize for the inconvenience. You can retry submitting or contact our support team for help."}</p>}
                            </div>
                            : <>
                                <div className="alert__details">
                                    {type == 'success' ? <div>
                                        {projectType?.id == 1 || projectType?.id == 2 ?
                                            <p>Please approve the templates from networks before scheduling calls.</p> : ''
                                        }
                                    </div>
                                        : <p className="error-text">{errorMsg ? errorMsg : "Your request couldn&lsquo;t be submitted due to a technical glitch. We apologize for the inconvenience. You can retry submitting or contact our support team for help."}</p>}
                                </div>
                                {type == 'success' && <div className="alert__note">
                                    <div className="note-heading"><img src={Info}></img><div className="heading-text">Note</div></div>
                                    <ul>
                                        <li><div className="list-icon">&#8226;</div><div>If you have selected Global providers then you will receive expert profiles on the External Expert Hub.</div></li>
                                        <li><div className="list-icon">&#8226;</div><div>If you have selected Regional providers then you will receive expert profiles on email outside the External Expert Hub.</div></li>
                                    </ul>
                                </div>
                                }
                            </>
                        }
                        <div className="alert__action-container">
                            {type == 'success' ? <button className="primary" onClick={() => successAlertButtonClick()}>Close</button>
                                : <>
                                    <MailButton label="Get Help" mailto={`mailto:externalexperthubL3@bcg.com?subject=Request%20for%20expert%20 ${requestType} %20Submission%20failed`} />
                                    <button className="primary" onClick={() => tryAgainButtonClick()}>Submit Again</button>
                                </>}
                        </div>
                    </>
                }
            </div>
        </Modal>
    );
};
