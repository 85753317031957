import React, { useState } from "react";
import SlackIcon from "assets/images/slack-icon.svg";
import Image from "components/shared/Image";
import './ExternalNetworks.scss';

export const ExternalNetworks = ({ extdata }) => {
    const data2 = ['Dialectica (Europe and NAMR)', 'Dialectica (Europe and NAMR)', 'Mimir (Japan)', 'Dialectica (Europe and NAMR)', 'Infollion (India)', 'VisasQ (Japan)', 'Dialectica (Europe and NAMR)', 'AlphaSights', 'Dialectica (Europe and NAMR)', 'Dialectica (Europe and NAMR)', 'Mimir (Japan)'];
    const data = [{
        name: 'Dialectica (Europe and NAMR)',
        requestClosed: false
    },
    {
        name: 'Infollion (India)',
        requestClosed: false
    },
    {
        name: 'VisasQ (Japan)',
        requestClosed: false
    },
    {
        name: 'Dialectica (Europe and NAMR)',
        requestClosed: false
    },
    {
        name: 'AlphaSights',
        requestClosed: true
    },
    {
        name: 'Dialectica (Europe and NAMR)',
        requestClosed: false
    },
    {
        name: 'Infollion (India)',
        requestClosed: false
    },
    {
        name: 'VisasQ (Japan)',
        requestClosed: false
    },
    {
        name: 'Dialectica (Europe and NAMR)',
        requestClosed: false
    },
    {
        name: 'AlphaSights',
        requestClosed: false
    },
    ]
    return (
        <div className="externalNetworks__wrap">
            <div className="externalNetworks__headwrap">
                <div className="externalNetworks--head">
                    External Expert Networks
                </div>
                <div className="externalNetworks--count">
                    {extdata?.length}
                </div>
            </div>
            <div className="externalNetworks--subhead">
                You can chat with network by clicking on the card below
            </div>
            <div className="externalNetworks__body">
                <div className="row">
                    {
                        extdata?.map((item, index) =>
                            <div className="body__main" key={index}>
                                <div className="body__wrap" key={index}>
                                    <div className="body--item">
                                        <div className="body--itemText">
                                            {item?.name}</div>
                                    </div>
                                    <div className="body--img">
                                        <Image src={SlackIcon} alt="SlackIcon" className="slack--icon" />
                                    </div>
                                </div>
                            </div>
                        )
                    }
                </div>
            </div>
        </div>
    )
}