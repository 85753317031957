import { REQUEST_DETAILS } from 'redux/constants';

//  People CCO actions
export const getRequestDetailsAPIData = (data) => ({
    type: REQUEST_DETAILS.API_REQUEST_DETAILS_DATA,
    payload: { interviewRequestId: data.interviewID, type: data.type }
});

export const getRequestDetailsAPIDataPending = () => ({
    type: REQUEST_DETAILS.API_REQUEST_DETAILS_RESULTS_PENDING
});

export const getRequestDetailsAPIDataSuccess = (payload) => ({
    type: REQUEST_DETAILS.API_REQUEST_DETAILS_RESULTS_SUCCESS,
    payload
});

export const getRequestDetailsAPIDataFailure = (error) => ({
    type: REQUEST_DETAILS.API_REQUEST_DETAILS_RESULTS_FAILURE,
    payload: { error: error?.toString() }
});