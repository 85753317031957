import { APPROVE_TEMPLATES } from "redux/constants";
export const name = "approveTemplates";

// initial state
export const initialState = {
    loading: true,
    error: false,
    errorMessage: "",
    approveTemplatesData: [],
};

// reducer selectors
export const selectors = {
    getLoading: state => state[name].loading,
    getError: state => state[name].error,
    getErrorMessage: state => state[name].errorMessage,
    getApproveTemplatesData: (state) => state[name]?.approveTemplatesData ? state[name]?.approveTemplatesData : "",
};

// reducer
export function reducer(state = initialState, action) {
    switch (action.type) {
        case APPROVE_TEMPLATES.API_APPROVE_TEMPLATES_RESULTS_FAILURE:
            return {
                ...state,
                loading: false,
                error: true,
                errorMessage: action.payload,
            };
        case APPROVE_TEMPLATES.API_APPROVE_TEMPLATES_RESULTS_PENDING:
            return {
                ...state,
                loading: action?.payload?.approvedTemplates <= 0 ? true : false,
                error: false,
                errorMessage: '',
            };
        case APPROVE_TEMPLATES.API_APPROVE_TEMPLATES_RESULTS_SUCCESS:
            return {
                ...state,
                loading: false,
                approveTemplatesData: action?.payload?.approvedTemplates,
                errorMessage: action?.payload?.errors && action?.payload?.errors[0],
            };
            case APPROVE_TEMPLATES.CLEAR_APPROVE_TEMPLATES:
                return{
                    ...state,
                    approveTemplatesData: [],
                }
        default:
            return state;
    }
}