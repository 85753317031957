import { EMP_DESIGNATION } from "redux/constants";
export const name = "empDesignation";

// initial state
export const initialState = {
    loading: true,
    designation: '',
};

// reducer selectors
export const selectors = {
    getDesignation: state => state[name].designation
};

// reducer
export function reducer(state = initialState, action) {
    switch (action.type) {
        case EMP_DESIGNATION.API_EMP_DESIGNATION_FAILURE:
            return {
                designation: ''
            };
        case EMP_DESIGNATION.API_EMP_DESIGNATION_SUCCESS:
            return {
                designation: action?.payload
            };
        default:
            return state;
    }
}