import { put, call, takeLatest } from 'redux-saga/effects';
// Config
import CONFIG from "config";
import { DELETE_FILE } from "redux/constants";
// Axios Util
import { doAxiosRequest } from "config-axios";
// Actions
import {
    getDeleteFileAPIDataPending,
    getDeleteFileAPIDataFailure,
    getDeleteFileAPIDataSuccess
} from "./deleteAttachment.actions";

import { setError } from '../error/error.actions';

export function* deleteFileRequestData(requestData) {
    const data = requestData.payload ? requestData.payload : null;
    if (data?.folder) {
        try {
            const axiosConfig = {
                method: 'DELETE',
                endpoint: `${CONFIG.API_URL.DELETE_FILE}?folderName=upload/${data.folder}&fileName=${data.file}`,
                headers: {
                    'x-api-key': CONFIG.BE_X_API_KEY,
                    'content-type': CONFIG.HTTP_CONTENT_TYPES.json,
                },
            };
            yield put(getDeleteFileAPIDataPending());
            const response = yield call(doAxiosRequest, axiosConfig);
            if (response) {
                yield put(getDeleteFileAPIDataSuccess(response));
            }
            else {
                yield put(getDeleteFileAPIDataFailure(CONFIG.DISPLAY_TEXT.ERROR_MESSAGES.NOT_FOUND));
            }
        }
        catch (err) {
            yield (put(setError(err)));
            yield put(getDeleteFileAPIDataFailure(CONFIG.DISPLAY_TEXT.ERROR_MESSAGES.NOT_FOUND));
        }
    }
}

export default function* deleteFileRequestAPISaga() {
    try {
        yield takeLatest(DELETE_FILE.API_DELETE_FILE, deleteFileRequestData);

    } catch (error) {
        yield (put(setError(error)));
    }
}