import { SHARE_VIA_EMAIL } from 'redux/constants';

//  People CCO actions
export const getShareViaEmailAPIData = (approvalData) => ({
    type: SHARE_VIA_EMAIL.API_SHARE_VIA_EMAIL_DATA,
    payload: approvalData
});

export const getShareViaEmailAPIDataPending = () => ({
    type: SHARE_VIA_EMAIL.API_SHARE_VIA_EMAIL_RESULTS_PENDING
});

export const getShareViaEmailAPIDataSuccess = (payload) => ({
    type: SHARE_VIA_EMAIL.API_SHARE_VIA_EMAIL_RESULTS_SUCCESS,
    payload
});

export const getShareViaEmailAPIDataFailure = (error) => ({
    type: SHARE_VIA_EMAIL.API_SHARE_VIA_EMAIL_RESULTS_FAILURE,
    payload: { error: error?.toString() }
});


export const resetShareViaEmailAPIData = () => ({
    type: SHARE_VIA_EMAIL.API_SHARE_VIA_EMAIL_RESULTS_RESET
})