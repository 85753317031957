import { put, call, takeLatest, takeEvery } from 'redux-saga/effects';
import CONFIG from "config";
import { CALLS } from "redux/constants";
import { doAxiosRequest } from "config-axios";
import {
    getCallsAPIDataPending,
    getCallsAPIDataFailure,
    getCallsAPIDataSuccess
} from "./calls.actions";

import { setError } from '../error/error.actions';


export function* getCallsAPIData(action) {
    try {
        const { API_URL: { GET_CALLS } } = CONFIG;
        const paramURL = GET_CALLS(action?.payload?.page, action?.payload?.sortBy);

        const axiosConfig = {
            method: 'POST',
            endpoint: paramURL,
            headers: {
                'x-api-key': CONFIG.BE_X_API_KEY,
                'content-type': CONFIG.HTTP_CONTENT_TYPES.json,
            },
            params: action?.payload?.data
        };
        yield put(getCallsAPIDataPending(action?.payload?.page));
        const response = yield call(doAxiosRequest, axiosConfig);
        // if (response) {
            yield put(getCallsAPIDataSuccess(response));
        // }
    }
    catch (err) {
        yield (put(setError(err)));
        yield put(getCallsAPIDataFailure(CONFIG.DISPLAY_TEXT.ERROR_MESSAGES.NOT_FOUND));
    }
}

export default function* callsAPISaga() {
    try {
        yield takeEvery(CALLS.API_CALLS, getCallsAPIData);

    } catch (error) {
        yield (put(setError(error)));
    }
}