import { TEMPLATE_FILTERS } from 'redux/constants';

//  People CCO actions
export const getTemplatesAPIData = (requestId) => ({
    type: TEMPLATE_FILTERS.API_TEMPLATE_FILTERS_DATA,
    payload: requestId
});

export const getTemplatesAPIDataPending = () => ({
    type: TEMPLATE_FILTERS.API_TEMPLATE_FILTERS_RESULTS_PENDING
});

export const getTemplatesAPIDataSuccess = (payload) => ({
    type: TEMPLATE_FILTERS.API_TEMPLATE_FILTERS_RESULTS_SUCCESS,
    payload
});

export const getTemplatesAPIDataFailure = (error) => ({
    type: TEMPLATE_FILTERS.API_TEMPLATE_FILTERS_RESULTS_FAILURE,
    payload: { error: error?.toString() }
});
