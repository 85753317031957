import { EXTERNAL_NETWORK } from "redux/constants";
export const name = "externalNetwork";

// initial state
export const initialState = {
    loading: true,
    error: false,
    errorMessage: "",
    externalNetworkResults: [],
    noRecordFound: false,
};

// reducer selectors
export const selectors = {
    getLoading: state => state[name].loading,
    getError: state => state[name].error,
    getErrorMessage: state => state[name].errorMessage,
    getExternalNetworkResults: (state) => state[name]?.externalNetworkResults ? state[name]?.externalNetworkResults : "",
    getNoRecordFound: state => state[name].noRecordFound,
};

// reducer
export function reducer(state = initialState, action) {
    switch (action.type) {
        case EXTERNAL_NETWORK.API_EXTERNAL_NETWORK_RESULTS_FAILURE:
            return {
                ...state,
                loading: false,
                error: true,
                errorMessage: action.payload
            };
        case EXTERNAL_NETWORK.API_EXTERNAL_NETWORK_RESULTS_PENDING:
            return {
                ...state,
                loading: true,
                noRecordFound: false,
                error: false,
                errorMessage: ''
            };
        case EXTERNAL_NETWORK.API_EXTERNAL_NETWORK_RESULTS_SUCCESS:
            return {
                ...state,
                loading: false,
                noRecordFound: action.payload?.length <= 0,
                externalNetworkResults: action.payload
            };
        default:
            return state;
    }
}