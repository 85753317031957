const data = {
    page: {
        pageInfo: {
            pageName: '',
            previousPageName: '',
        },
        headerInfo: {
            toolName: 'EEH',
            staffSystemID: '',
            interviewRequestId: '',
            authorStaffId: ''
        },
        category: {
            primaryCategory: 'External Experts Hub'
        }
    },
    tabName: 'Request details',
    serviceCheckboxClick: ''
}

window.digitalData = data;
export default window.digitalData;