import React from 'react';
import uploadIcon from '../../../assets/images/upload_icon.svg';
import './UploadAttachments.scss';

export const UploadAttachments = ({uploadFileHandler}) => {
    return (
        <>
            <div className="upload-label">Add Attachments (Optional)</div>
            <div className="upload-link">
                <input className="main-upload-field" type="file" onChange={(event) => {uploadFileHandler(event);}}/>
                <img alt="uploadIcon" src={uploadIcon}></img><span>Upload Attachment</span>
            </div>
        </>
    )
}