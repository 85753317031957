import { put, call, takeLatest } from 'redux-saga/effects';
// Config
import CONFIG from "config";
import { CANCEL_CALL } from "redux/constants";
// Axios Util
import { doAxiosRequest } from "config-axios";
import { moduleFailure } from "redux/global.action.js";
// Actions
import {
    cancellationCallAPIDataPending,
    cancellationCallAPIDataFailure,
    cancellationCallAPIDataSuccess
} from "./callCancellation.actions";

import { setError } from '../error/error.actions';


export function* cancelCallAPIData(action) {
    try {
        const axiosConfig = {
            method: 'POST',
            endpoint: CONFIG.API_URL.CANCEL_CALL,
            headers: {
                'x-api-key': CONFIG.BE_X_API_KEY,
                'content-type': CONFIG.HTTP_CONTENT_TYPES.json
            },
            params: action?.payload,
        };
        yield put(cancellationCallAPIDataPending(action?.payload));
        const response = yield call(doAxiosRequest, axiosConfig);
        if (response) {
            yield put(cancellationCallAPIDataSuccess(response));
        }
        else {
            yield put(cancellationCallAPIDataFailure(CONFIG.DISPLAY_TEXT.ERROR_MESSAGES.NOT_FOUND));
        }
    }
    catch (err) {
        yield (put(setError(err)));
        yield put(cancellationCallAPIDataFailure(CONFIG.DISPLAY_TEXT.ERROR_MESSAGES.NOT_FOUND));
    }
}

export default function* cancelCallAPISaga() {
    try {
        yield takeLatest(CANCEL_CALL.API_CANCEL_CALL, cancelCallAPIData);

    } catch (error) {
        yield (put(setError(error)));
    }
}