import { put, call, takeLatest } from 'redux-saga/effects';
// Config
import CONFIG from "config";
import { CHARGE_CODE } from "redux/constants";
// Axios Util
import { doAxiosRequest } from "config-axios";
import { moduleFailure } from "redux/global.action.js";
// Actions
import {
    getChargeCodeAPIDataFailure,
    getChargeCodeAPIDataPending,
    getChargeCodeAPIDataSuccess
} from "./chargeCode.actions";

import { setError } from '../error/error.actions';


export function* getChargeCodeAPIData(action) {
    let formatData = [];
    const { API_URL: { GET_CHARGE_CODES } } = CONFIG;
    const paramURL = GET_CHARGE_CODES(action?.payload);
    try {
        const axiosConfig = {
            method: 'GET',
            endpoint: paramURL,
            headers: {
                'x-api-key': CONFIG.BE_X_API_KEY,
                'content-type': CONFIG.HTTP_CONTENT_TYPES.json,
            },
        };
        yield put(getChargeCodeAPIDataPending());
        const response = yield call(doAxiosRequest, axiosConfig);
        if (response) {
            yield put(getChargeCodeAPIDataSuccess(response?.data));
        }
        // else {
        //     yield put(getChargeCodeAPIDataFailure(CONFIG.DISPLAY_TEXT.ERROR_MESSAGES.NOT_FOUND));
        // }
    }
    catch (err) {
        yield (put(setError(err)));
        yield put(getChargeCodeAPIDataFailure(CONFIG.DISPLAY_TEXT.ERROR_MESSAGES.NOT_FOUND));
    }
}

export default function* chargeCodeAPISaga() {
    try {
        yield takeLatest(CHARGE_CODE.API_CHARGE_CODE_DATA, getChargeCodeAPIData);

    } catch (error) {
        yield (put(setError(error)));
    }
}