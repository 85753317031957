import { REQUEST_DETAILS } from "redux/constants";
export const name = "requestDetails";

// initial state
export const initialState = {
    loading: true,
    error: false,
    errorMessage: "",
    requestDetailsData: [],
    noRecordFound: false,
};

// reducer selectors
export const selectors = {
    getLoading: state => state[name].loading,
    getError: state => state[name].error,
    getErrorMessage: state => state[name].errorMessage,
    getRequestDetailsResults: (state) => state[name]?.requestDetailsData ? state[name]?.requestDetailsData : "",
    getUpdateDetailsResult: (state) => state[name]?.updateDetailsData ? state[name]?.updateDetailsData : "",
    getNoRecordFound: state => state[name].noRecordFound,
};

// reducer
export function reducer(state = initialState, action) {
    switch (action.type) {
        case REQUEST_DETAILS.API_REQUEST_DETAILS_RESULTS_FAILURE:
            return {
                ...state,
                loading: false,
                error: true,
                errorMessage: action.payload?.data,
            };
        case REQUEST_DETAILS.API_REQUEST_DETAILS_RESULTS_PENDING:
            return {
                ...state,
                loading: true,
                noRecordFound: false,
                error: false,
                errorMessage: '',
            };
        case REQUEST_DETAILS.API_REQUEST_DETAILS_RESULTS_SUCCESS:
            return {
                ...state,
                loading: false,
                noRecordFound: action.payload?.data?.length <= 0,
                requestDetailsData: action?.payload?.data ? action?.payload?.data : state.requestDetailsData,
                updateDetailsData: action?.payload?.reqType ? action?.payload : state.updateDetailsData,
            };
        default:
            return state;
    }
}