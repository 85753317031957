// constant
import { ENTITLEMENTS, AUTH } from "redux/constants";
import CONSTANTS from "globalConstants";
// Config
import CONFIG from "config";

const {
  API_STATUS: {
    START
  }
} = CONSTANTS;

// local variable
export const name = "entitlements";
// initial state
export const initialState = {
  products: [],
  apiCallStatus: START,
  isSiteAdmin: false,
  error: "",
  bearerToken: {
    loading: false,
    isBearerTokenSuccess: false,
  },
  token:"",
};

// reducer selectors
export const selectors = {
  getApiCallStatus: (state) => state[name].apiCallStatus,
  checkSiteAdmin: (state) => state[name].isSiteAdmin,
  getTokenSuccessStatus: (state) => state[name].bearerToken.isBearerTokenSuccess,
  getBearerToken: (state) => state[name].token.response,
  getTokenLoadingStatus: (state) => state[name].bearerToken.loading
};

const setIsSiteAdmin = (state, payload) => {
  return {
    ...state,
    isSiteAdmin: payload.res,
    isLoading: false
  }
}

const setIsLoading = (state) => {
  return {
    ...state,
    isLoading: true,
    error: "",
  }
}

const setError = (state, payload) => {
  return {
    ...state,
    error: payload.error,
    isLoading: false,
  }
}

const setBearerTokenForPending = (state, payload) => {
  const { bearerToken } = state;
  return Object.assign({}, state, {
    ...state,
    isLoading: true,
    error: "",
    bearerToken: {
      ...bearerToken,
      loading: true,
    },
  });
}

const setBearerToken= (state, payload) =>{
  const {bearerToken} = state;
  return{
    ...state,
    token: payload,
    isLoading: false
  }
}

const setBearerTokenForSuccess = (state, payload) => {
  const { response } = payload;
  const { bearerToken } = state;
  return Object.assign({}, state, {
    isLoading: false,
    error: "",
    bearerToken: {
      ...bearerToken,
      loading: false,
      isBearerTokenSuccess: response
    },
  });
}

export function reducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case ENTITLEMENTS.API_CHECK_SITE_ADMIN_PENDING:
      return setIsLoading(state, payload);
    case ENTITLEMENTS.API_CHECK_SITE_ADMIN_SUCCESS:
      return setIsSiteAdmin(state, payload);
    case ENTITLEMENTS.API_CHECK_SITE_ADMIN_FAILURE:
      return setError(state, payload);
    case AUTH.GET_SMARTLOGIC_BEARER_TOKEN_PENDING:
      return setBearerTokenForPending(state);
      case AUTH.GET_SMARTLOGIC_BEARER_TOKEN:
      return setBearerToken(state, payload);
    case AUTH.GET_SMARTLOGIC_BEARER_TOKEN_FAILURE:
      return setError(state, payload);
    case AUTH.GET_SMARTLOGIC_BEARER_TOKEN_SUCCESS:
      return setBearerTokenForSuccess(state, payload);
    default:
      return state;
  }
}
