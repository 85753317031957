import { CHARGE_CODE_DETAILS } from "redux/constants";
export const name = "chargeCodeDetails";

// initial state
export const initialState = {
    loading: true,
    error: false,
    errorMessage: "",
    chargeCodeDetails: {},
    noRecordFound: false,
};

// reducer selectors
export const selectors = {
    getLoading: state => state[name].loading,
    getError: state => state[name].error,
    getErrorMessage: state => state[name].errorMessage,
    getChargeCodeDetails: (state) => state[name]?.chargeCodeDetails ? state[name]?.chargeCodeDetails : "",
    getNoRecordFound: state => state[name].noRecordFound,
};

// reducer
export function reducer(state = initialState, action) {
    switch (action.type) {
        case CHARGE_CODE_DETAILS.API_CHARGE_CODE_DETAILS_RESULTS_FAILURE:
            return {
                ...state,
                loading: false,
                error: true,
                errorMessage: action?.payload?.data,
            };
        case CHARGE_CODE_DETAILS.API_CHARGE_CODE_DETAILS_RESULTS_PENDING:
            return {
                ...state,
                loading: true,
                noRecordFound: false,
                error: false,
                errorMessage: '',
            };
        case CHARGE_CODE_DETAILS.API_CHARGE_CODE_DETAILS_RESULTS_SUCCESS:
            return {
                ...state,
                loading: false,
                noRecordFound: action.payload?.data?.length <= 0,
                chargeCodeDetails: action?.payload,
            };
        default:
            return state;
    }
}