import { DELETE_FILE } from 'redux/constants';

//  People CCO actions
export const getDeleteFileAPIData = (requestData) => ({
    type: DELETE_FILE.API_DELETE_FILE,
    payload: requestData
});

export const getDeleteFileAPIDataPending = () => ({
    type: DELETE_FILE.API_DELETE_FILE_PENDING
});

export const getDeleteFileAPIDataSuccess = (payload) => ({
    type: DELETE_FILE.API_DELETE_FILE_SUCCESS,
    payload
});

export const getDeleteFileAPIDataFailure = (error) => ({
    type: DELETE_FILE.API_DELETE_FILE_FAILURE,
    payload: { error: error?.toString() }
});
