// constant
import { USER_SESSION } from "redux/constants";
export const name = "usersession";

// initial state
export const initialState = {
  activeUserSession: {
    data: {},
    isLoading: false,
    error: ''
  }
};
// reducer selectors
export const selectors = {
  getActiveUserSession: (state) => state[name].activeUserSession?.data?.chat_session_id || '',
  isActiveUserSessionLoading: (state) => state[name].activeUserSession.isLoading,
};

const setActiveUserSessionError = (state, payload) => {
  return Object.assign({}, state, {
    activeUserSession: {
      ...initialState.activeUserSession,
      error: payload.error
    },
  });
};

const setActiveUserSessionPending = (state) => {
  return Object.assign({}, state, {
    activeUserSession: {
      ...initialState.activeUserSession,
      isLoading: true
    },
  });
};

const setActiveUserSession = (state, payload) => {
  return Object.assign({}, state, {
    activeUserSession: {
      ...initialState.activeUserSession,
      data: payload.data
    },
  });
};


export function reducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case USER_SESSION.ACTIVE_USER_SESSION_PENDING:
      return setActiveUserSessionPending(state);
    case USER_SESSION.ACTIVE_USER_SESSION_FAILURE:
      return setActiveUserSessionError(state, payload);
    case USER_SESSION.ACTIVE_USER_SESSION_SUCCESS:
      return setActiveUserSession(state, payload);
    default:
      return state;
  }
}
