import { put, call, takeLatest } from 'redux-saga/effects';
// Config
import CONFIG from "config";
import { EXTERNAL_NETWORK } from "redux/constants";
// Axios Util
import { doAxiosRequest } from "config-axios";
import { moduleFailure } from "redux/global.action.js";
// Actions
import {
    getExternalNetworkAPIDataPending,
    getExternalNetworkAPIDataFailure,
    getExternalNetworkAPIDataSuccess
} from "./externalNetwork.actions";

import { setError } from '../error/error.actions';


export function* getExternalNetworkAPIData() {
    let formatData = [];
    try {
        const axiosConfig = {
            method: 'GET',
            endpoint: CONFIG.API_URL.GET_EXTERNAL_NETWORKS,
            headers: {
                'x-api-key': CONFIG.BE_X_API_KEY,
                'content-type': CONFIG.HTTP_CONTENT_TYPES.json,
            },
        };
        yield put(getExternalNetworkAPIDataPending());
        const response = yield call(doAxiosRequest, axiosConfig);
        if (response) {
            let temp = response?.eenProviders;
            temp?.map((item, index) => {
                formatData.push({
                    providerName: item.name,
                    providerType: item?.type,
                    id: item.id,
                    forSurvey: item.application == "Interview_Survey"
                })
            })
            yield put(getExternalNetworkAPIDataSuccess(formatData));
        }
        // else {
        //     yield put(getExternalNetworkAPIDataFailure(CONFIG.DISPLAY_TEXT.ERROR_MESSAGES.NOT_FOUND));
        // }
    }
    catch (err) {
        yield (put(setError(err)));
        yield put(getExternalNetworkAPIDataFailure(CONFIG.DISPLAY_TEXT.ERROR_MESSAGES.NOT_FOUND));
    }
}

export default function* externalNetworkAPISaga() {
    try {
        yield takeLatest(EXTERNAL_NETWORK.API_EXTERNAL_NETWORK_DATA, getExternalNetworkAPIData);

    } catch (error) {
        yield (put(setError(error)));
    }
}