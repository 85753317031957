import React, { useState } from 'react';
import ErrorIcon from "assets/images/error_screen.svg";
import Image from "components/shared/Image";
import './ErrorScreen.scss';

export const ErrorScreen = () => {
    return (
        // <Layout id="errorscreen" layoutClass="layout__my-navigator">
            <div className='error-screen-wrap'>
                <div className='error-screen-body'>
                    <div className='error-body-left'>
                        <Image src={ErrorIcon} alt="ErrorIcon" />
                    </div>
                    <div className='error-body-right'>
                        <div className='error-head'>
                            Oops, Something Went Wrong!
                        </div>
                        <div className='error-subhead'>
                            We&#39;re currently facing a technical hiccup!
                        </div>
                        <div className='error-text'>
                            Please try refreshing the page or click on &quot;Raise an Issue&quot; to notify us of this problem. Your feedback helps us improve!
                        </div>
                        <div className='error-btns'>
                            <button className="error-refresh" onClick={() => window?.location?.reload()}>Refresh</button>
                            <button className="error-req-raise" onClick={() => window.open('mailto:bostonconsultinggroup@service-now.com')}>Raise an issue</button>
                        </div>
                    </div>
                </div>
                {/* <div className='divider'></div> */}
                {/* <div className='error-screen-footer'>
                <div className='error-footer-text'>
                    While we sort this out, why not explore a wealth of internal expertise and resources available to you? Here are some quick links to get you started:
                </div>
                <div className='error-footer-links'>
                    <a href="">Research, Knowledge & Expertise (DRS, PA KT)</a>
                    <a href="">Research Center</a>
                    <a href="">KN Expert Search</a>
                    <a href="">Outsourced Research</a>
                </div>
            </div> */}
            </div>
        // </Layout>
    )
}