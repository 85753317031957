import { put, call, takeLatest } from 'redux-saga/effects';
import CONFIG from "config";
import { CALLS_FILTERS } from "redux/constants";
import { doAxiosRequest } from "config-axios";
import {
    getCallsFiltersAPIDataPending,
    getCallsFiltersAPIDataFailure,
    getCallsFiltersAPIDataSuccess
} from "./callsFilters.actions";

import { setError } from '../error/error.actions';


export function* getCallsFiltersData(action) {
    try {
        const { API_URL: { GET_CALLS_FILTERS } } = CONFIG;
        const paramURL = GET_CALLS_FILTERS(action?.payload);
        const axiosConfig = {
            method: 'GET',
            endpoint: paramURL,
            headers: {
                'x-api-key': CONFIG.BE_X_API_KEY,
                'content-type': CONFIG.HTTP_CONTENT_TYPES.json,
            },
        };
        yield put(getCallsFiltersAPIDataPending());
        const response = yield call(doAxiosRequest, axiosConfig);
        yield put(getCallsFiltersAPIDataSuccess(response));
    }
    catch (err) {
        yield (put(setError(err)));
        yield put(getCallsFiltersAPIDataFailure(CONFIG.DISPLAY_TEXT.ERROR_MESSAGES.NOT_FOUND));
    }
}

export default function* callsFiltersAPISaga() {
    try {
        yield takeLatest(CALLS_FILTERS.API_CALLS_FILTERS_DATA, getCallsFiltersData);

    } catch (error) {
        yield (put(setError(error)));
    }
}