import { OPEN_REQUESTS } from 'redux/constants';

//  People CCO actions
export const getOpenRequestAPIData = (data) => ({
    type: OPEN_REQUESTS.API_OPEN_REQUESTS_DATA,
    payload: data
});

export const getOpenRequestAPIDataPending = () => ({
    type: OPEN_REQUESTS.API_OPEN_REQUESTS_RESULTS_PENDING
});

export const getOpenRequestAPIDataSuccess = (payload) => ({
    type: OPEN_REQUESTS.API_OPEN_REQUESTS_RESULTS_SUCCESS,
    payload
});

export const getOpenRequestAPIDataFailure = (error) => ({
    type: OPEN_REQUESTS.API_OPEN_REQUESTS_RESULTS_FAILURE,
    payload: { error: error?.toString() }
});
