import { CANCEL_CALL } from 'redux/constants';

//  People CCO actions
export const cancellationCallAPIData = (data) => ({
    type: CANCEL_CALL.API_CANCEL_CALL,
    payload: data
});

export const cancellationCallAPIDataPending = () => ({
    type: CANCEL_CALL.API_CANCEL_CALL_RESULTS_PENDING
});

export const cancellationCallAPIDataSuccess = (payload) => ({
    type: CANCEL_CALL.API_CANCEL_CALL_RESULTS_SUCCESS,
    payload
});

export const cancellationCallAPIDataFailure = (error) => ({
    type: CANCEL_CALL.API_CANCEL_CALL_RESULTS_FAILURE,
    payload: { error: error?.toString() }
});

export const clearcancellationCallAPIData = () => ({
    type: CANCEL_CALL.API_CLEAR_CANCEL_CALL,
});