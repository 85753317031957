export const formatDate = (inputDate) => {
    if (!inputDate) {
        return ''
    }
    // Create a new Date object from the input string
    const date = new Date(inputDate);

    // Define months array
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

    // Get day, month, and year
    const day = date.getDate();
    const month = months[date.getMonth()];
    const year = date.getFullYear();

    // Format the date
    const formattedDate = `${day} ${month} ${year}`;

    return formattedDate;
};
