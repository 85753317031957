import { EXPERT_TYPES } from "redux/constants";
export const name = "expertTypes";

// initial state
export const initialState = {
    loading: true,
    error: false,
    errorMessage: "",
    expertTypesResults: [],
    noRecordFound: false,
};

// reducer selectors
export const selectors = {
    getLoading: state => state[name].loading,
    getError: state => state[name].error,
    getErrorMessage: state => state[name].errorMessage,
    getexpertTypesResults: (state) => state[name]?.expertTypesResults ? state[name]?.expertTypesResults : "",
    getNoRecordFound: state => state[name].noRecordFound,
};

// reducer
export function reducer(state = initialState, action) {
    switch (action.type) {
        case EXPERT_TYPES.API_EXPERT_TYPES_RESULTS_FAILURE:
            return {
                ...state,
                loading: false,
                error: true,
                errorMessage: action.payload
            };
        case EXPERT_TYPES.API_EXPERT_TYPES_RESULTS_PENDING:
            return {
                ...state,
                loading: true,
                noRecordFound: false,
                error: false,
                errorMessage: ''
            };
        case EXPERT_TYPES.API_EXPERT_TYPES_RESULTS_SUCCESS:
            return {
                ...state,
                loading: false,
                noRecordFound: action.payload?.length <= 0,
                expertTypesResults: action.payload
            };
        default:
            return state;
    }
}