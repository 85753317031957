import { EXP_REC_FILTERS } from 'redux/constants';

//  People CCO actions
export const getExpRecAPIData = (requestId) => ({
    type: EXP_REC_FILTERS.API_EXP_REC_FILTERS_DATA,
    payload: requestId
});

export const getExpRecAPIDataPending = () => ({
    type: EXP_REC_FILTERS.API_EXP_REC_FILTERS_RESULTS_PENDING
});

export const getExpRecAPIDataSuccess = (payload) => ({
    type: EXP_REC_FILTERS.API_EXP_REC_FILTERS_RESULTS_SUCCESS,
    payload
});

export const getExpRecAPIDataFailure = (error) => ({
    type: EXP_REC_FILTERS.API_EXP_REC_FILTERS_RESULTS_FAILURE,
    payload: { error: error?.toString() }
});
