import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import { selectors as peopleSelector } from '../../redux/reducers/peopleresults/peopleresults_reducer';
import { Image } from 'semantic-ui-react';
import logoIcon from "assets/images/logo.svg";
import notificationIcon from "assets/images/header_notification_icon.svg";
import { sendToLocalStorage } from 'utils/localStorage/localStorage';
import { getRandomColor } from "utils/helpers/helpers";
import { getEmployeeDesignation } from 'redux/actions/employeeDesignation/employeeDesignation.actions';
import { getStaffId, initiateRequestFormClickAnalytics, globalAdobeAnalytics } from '../../analytics.js';
import { getUserProfilePictures } from "api/getUserProfilePictures";
import { removeFromLocalStorage } from 'utils/localStorage/localStorage';
import { getUserId } from "utils/auth/auth";
import "./Header.scss";

const Header = ({ history, isError }) => {
  const getPeopleDataReducer = useSelector(peopleSelector.getPeopleResults);
  const [userProfile, setUserProfile] = useState(getPeopleDataReducer[0]?.picture);
  const [userDetails, setUserDetails] = useState({ nameInitials: '', bgColor: '' });
  const [hrid, setHrid] = useState();
  const dispatch = useDispatch();

  useEffect(() => {
    fetchHrId();
    fetchLoggedInUserEmail
  }, []);

  const fetchLoggedInUserEmail = async () => {
    const hrid = await getUserId();
    const resp = await getUserProfilePictures([hrid]);
    if (resp?.length > 0) {
      getStaffId(resp[0]?.email);
    }
  }

  useEffect(() => {
    if (getPeopleDataReducer[0] && getPeopleDataReducer[0].id == hrid) {
      const profilePic = getPeopleDataReducer[0]?.picture?.includes('2b57bfed-513c-4f78-beca-f6a3dd6a75be.jpg') ? null : getPeopleDataReducer[0]?.picture;
      setUserProfile(profilePic);
      const nameArray = getPeopleDataReducer[0]?.preferredName ? getPeopleDataReducer[0]?.preferredName.split(', ') : [];
      let userInitials = '';
      if (nameArray.length > 0) {
        userInitials = nameArray.length > 1 ? nameArray[1].charAt(0) + nameArray[0].charAt(0) : nameArray[0].charAt(0);
      }
      setUserDetails(
        {
          nameInitials: userInitials,
          bgColor: getRandomColor()
        });
      sendToLocalStorage('phoneNumber', getPeopleDataReducer[0]?.phones || []);
      dispatch(getEmployeeDesignation(getPeopleDataReducer[0]?.id));
    }
  }, [getPeopleDataReducer, hrid]);

  const fetchHrId = async () => {
    const hrid = await getUserId();
    setHrid(hrid);
  }

  const handleButtonClick = () => {
    sessionStorage.clear('hasFormLoaded')
    if (window?.location?.pathname === '/') {
      globalAdobeAnalytics('EEH Request Form', 'EEH Home Page');
      initiateRequestFormClickAnalytics('EEH Request Form', 'Initiate Expert Request', 'EEH Home Page');
    }
    else {
      globalAdobeAnalytics('EEH Request Form', 'EEH Request Details Page');
      initiateRequestFormClickAnalytics('EEH Request Form', 'Initiate Expert Request', 'EEH Request Details Page');
    }
    history.push("/ExpertRequest/initiate");
  };

  let activepath = window?.location?.pathname;

  const handleLogoClick = () => {
    sendToLocalStorage('notLoadHome', true);
    removeFromLocalStorage('hasDetailsLoaded');
    if (window.location?.pathname === '/') {
      globalAdobeAnalytics('EEH Home Page', 'EEH Home Page');
    }
    else if (window.location?.pathname === '/requestDetails') {
      globalAdobeAnalytics('EEH Home Page', 'EEH Request Details Page');
    }
    else if (window?.location?.pathname === '/ExpertRequest/initiate') {
      globalAdobeAnalytics('EEH Home Page', 'EEH Request Form');
    }
    history.push("/")
  }

  return (
    <div className="mainheader__wrap">
      <div className="mainheader__logo">
        <Image src={logoIcon} alt="logo"
          onClick={() => {
            handleLogoClick();
          }} />
      </div>

      <div className="mainheader__rightSection">
        {!activepath.includes('/ExpertRequest') && activepath !== '/error' && isError !== true &&
          <button className="initiate--expbtn" onClick={handleButtonClick}>
            Initiate Expert Request
          </button>
        }

        {
          isError !== true &&
          <div className="notiUser__wrap">
            <Image className="bell-img" src={notificationIcon} alt="notificationIcon" />
            {userProfile ?
              <Image className="header--img" src={userProfile} alt="defaultUserIcon" /> :
              <span style={{ background: userDetails.bgColor }} className={`initial-icon user--img custom--profile--pic`}>
                {userDetails.nameInitials}
              </span>
            }
          </div>
        }
      </div>
    </div>
  );
};

export default withRouter(Header);
