import { DUPLICATE_EXP } from 'redux/constants';

//  Communication Template actions
export const getDuplicateExpAPIData = (reqId, expName) => ({
    type: DUPLICATE_EXP.API_DUPLICATE_EXP,
    payload: {
        reqId: reqId,
        expName: expName
    }
});

export const getDuplicateExpAPIDataPending = (page) => ({
    type: DUPLICATE_EXP.API_DUPLICATE_EXP_PENDING,
    payload: page
});

export const getDuplicateExpAPIDataSuccess = (payload) => ({
    type: DUPLICATE_EXP.API_DUPLICATE_EXP_SUCCESS,
    payload
});

export const getDuplicateExpAPIDataFailure = (error) => ({
    type: DUPLICATE_EXP.API_DUPLICATE_EXP_FAILURE,
    payload: { error: error?.toString() }
});
