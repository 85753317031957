import './configAA.js';
const Hashes = require('jshashes/hashes');

const adobeData = {
    page: {
        pageInfo: {
            pageName: '',
            previousPageName: '',
        },
        headerInfo: {
            toolName: 'EEH',
            staffSystemID: '',
            interviewRequestId: '',
            authorStaffId: ''
        },
        category: {
            primaryCategory: 'External Experts Hub'
        }
    },
    tabName: '',
    serviceCheckboxClick: ''
}

export function getStaffId(email) {
    const systemStaffID = new Hashes.SHA256().hex(email?.toLowerCase());
    adobeData.page.headerInfo.staffSystemID = systemStaffID;
    window.digitalData = Object.assign(window.digitalData, adobeData);
}

export function globalAdobeAnalytics(pageName, previousname) {
    const adobePageData = adobeData.page.pageInfo;
    adobePageData.pageName = pageName;
    window.digitalData = Object.assign(window.digitalData, adobeData);
    if (previousname) {
        window.digitalData.page.pageInfo.previousPageName = previousname;
        adobePageData.previousPageName = previousname;
    }
    if (window._satellite) {
        window._satellite.track('pageView');
        return true;
    } else {
        return false;
    }
}

export function initiateRequestFormClickAnalytics(pageName, tabName, previousname) {
    window.digitalData.page.pageInfo.pageName = pageName;
    window.digitalData.tabName = tabName;
    window.digitalData.page.pageInfo.previousPageName = previousname;
    if (window._satellite) {
        window._satellite.track('requestFormClicks');
        return true;
    } else {
        return false;
    }
}

export function initiateTabClickAnalytics(pageName, tabName, previouspagename) {
    const adobePageData = adobeData.page.pageInfo;
    window.digitalData = Object.assign(window.digitalData, adobeData);
    window.digitalData.page.pageInfo.pageName = pageName;
    window.digitalData.page.pageInfo.previousPageName = previouspagename;
    window.digitalData.tabName = tabName;
    if (window._satellite) {
        window._satellite.track('tabClicks');
        return true;
    } else {
        return false;
    }
}

export function initiateSubmitClickAnalytics(pageName, checkboxArg, tabName, previousname) {
    const adobePageData = adobeData.page.pageInfo;
    adobePageData.pageName = pageName;
    adobeData.serviceCheckboxClick = checkboxArg;
    window.digitalData = Object.assign(window.digitalData, adobeData);
    window.digitalData.tabName = tabName;
    window.digitalData.page.pageInfo.previousPageName = previousname;
    if (window._satellite) {
        window._satellite.track('submitClicks');
        return true;
    } else {
        return false;
    }
}

export function initiateInterviewReqCardClickAnalytics(pagename, interviewId, previouspage) {
    const adobePageData = adobeData.page.pageInfo;
    adobePageData.pageName = pagename;
    window.digitalData.page.headerInfo.interviewRequestId = `${interviewId}`;
    // window.digitalData.page.pageInfo.previousPageName = 'EEH Home Page';
    window.digitalData.page.pageInfo.previousPageName = previouspage;
    window.digitalData = Object.assign(window.digitalData, adobeData);
    if (window._satellite) {
        window._satellite.track('interviewRequestClicks');
        return true;
    } else {
        return false;
    }
}

export function getAuthorStaffId(email) {
    const authorStaffId = new Hashes.SHA256().hex(email?.toLowerCase());
    window.digitalData.page.headerInfo.authorStaffId = authorStaffId;
}

export function initiateAuthorProfileClickAnalytics(pageName, tabNameArg, authorEmail, previousname) {
    const adobePageData = adobeData.page.pageInfo;
    getAuthorStaffId(authorEmail);
    if (!window.digitalData) {
        window.digitalData = {};
    }
    window.digitalData = Object.assign({}, window.digitalData, adobeData);
    window.digitalData.page.pageInfo.pageName = pageName;
    window.digitalData.page.headerInfo.interviewRequestId = '';
    window.digitalData.tabName = 'Request Details';
    window.digitalData.page.pageInfo.previousPageName = previousname;

    if (window._satellite) {
        window._satellite.track('authorProfileClicks');
        return true;
    } else {
        return false;
    }
}