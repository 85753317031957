import { EDIT_TEMPLATE } from "redux/constants";
export const name = "editTemplate";

// initial state
export const initialState = {
    loading: true,
    error: false,
    errorMessage: "",
    editTemplateData: '',
};

// reducer selectors
export const selectors = {
    getLoading: state => state[name].loading,
    getError: state => state[name].error,
    getErrorMessage: state => state[name].errorMessage,
    getEditTemplateData: (state) => state[name]?.editTemplateData ? state[name]?.editTemplateData : "",
};

// reducer
export function reducer(state = initialState, action) {
    switch (action.type) {
        case EDIT_TEMPLATE.API_EDIT_TEMPLATE_FAILURE:
            return {
                ...state,
                loading: false,
                error: true,
                errorMessage: action.payload,
            };
        case EDIT_TEMPLATE.API_EDIT_TEMPLATE_PENDING:
            return {
                ...state,
                loading: action?.payload?.templateId ? true : false,
                error: false,
                errorMessage: '',
            };
        case EDIT_TEMPLATE.API_EDIT_TEMPLATE_SUCCESS:
            return {
                ...state,
                loading: false,
                editTemplateData: action?.payload,
                // errorMessage: action?.payload?.errors && action?.payload?.errors[0],
            };
        default:
            return state;
    }
}