import { put, call, takeLatest } from 'redux-saga/effects';
// Config
import CONFIG from "config";
import { REQUEST_TYPE } from "redux/constants";
// Axios Util
import { doAxiosRequest } from "config-axios";
// Actions
import {
    getRequestTypeAPIDataPending,
    getRequestTypeAPIDataFailure,
    getRequestTypeAPIDataSuccess
} from "./requestType.actions";

import { setError } from '../error/error.actions';


export function* getRequestTypeAPIData() {
    let formatData = [];
    try {
        const axiosConfig = {
            method: 'GET',
            endpoint: CONFIG.API_URL.GET_REQUEST_TYPES,
            headers: {
                'x-api-key': CONFIG.BE_X_API_KEY,
                'content-type': CONFIG.HTTP_CONTENT_TYPES.json,
            },
        };
        yield put(getRequestTypeAPIDataPending());
        const response = yield call(doAxiosRequest, axiosConfig);
        if (response) {
            formatData = response?.projectTypes;
            yield put(getRequestTypeAPIDataSuccess(formatData));
        }
        // else {
        //     yield put(getRequestTypeAPIDataFailure(CONFIG.DISPLAY_TEXT.ERROR_MESSAGES.NOT_FOUND));
        // }
    }
    catch (err) {
        yield (put(setError(err)));
        yield put(getRequestTypeAPIDataFailure(CONFIG.DISPLAY_TEXT.ERROR_MESSAGES.NOT_FOUND));
    }
}

export default function* requestTypeAPISaga() {
    try {
        yield takeLatest(REQUEST_TYPE.API_REQUEST_TYPES_DATA, getRequestTypeAPIData);

    } catch (error) {
        yield (put(setError(error)));
    }
}