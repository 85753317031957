export const ROLES = {
  USER: 'user',
  ASSISTANT: 'assistant',
  STATUS: 'status'
};

// Notification toastify
export const ERROR = "ERROR";
export const INFO = "INFO";
export const SUCCESS = "SUCCESS";
export const WARNING = "WARNING";
export const DISMISS_NOTIFICATIONS = "DISMISS_NOTIFICATIONS";

// Global Actions
export const GLOBAL = {
  SHOW_PAGELOADER: "SHOW_PAGELOADER",
  HIDE_PAGELOADER: "HIDE_PAGELOADER",
  SHOW_FULLPAGELOADER: "SHOW_FULLPAGELOADER",
  HIDE_FULLPAGELOADER: "HIDE_FULLPAGELOADER",
  FULLPAGELOADER_ERROR: "FULLPAGELOADER_ERROR",
  DISABLE_BODY_OVERFLOW: "DISABLE_BODY_OVERFLOW",
  ENABLE_BODY_OVERFLOW: "ENABLE_BODY_OVERFLOW",
  LOGGEDIN_USER_DATA: "LOGGEDIN_USER_DATA"
};

export const GLOBAL_ERROR = {
  ERROR_HANDLING: 'ERROR_HANDLING'
};

export const PEOPLE_DATA = {
  API_PEOPLE_DATA: "API_PEOPLE_RESULTS",
  API_PEOPLE_RESULTS_PENDING: "API_PEOPLE_RESULTS_PENDING",
  API_PEOPLE_RESULTS_SUCCESS: "API_PEOPLE_RESULTS_SUCCESS",
  API_PEOPLE_RESULTS_FAILURE: "API_PEOPLE_RESULTS_FAILURE",
  CLEAR_PEOPLE_RESULTS: "API_CLEAR_PEOPLE_RESULTS",
  API_TYPEAHEAD_DATA_SUCCESS: "API_TYPEAHEAD_DATA_SUCCESS"
};

export const USER_SESSION = {
  GET_ACTIVE_USER_SESSION: "GET_ACTIVE_USER_SESSION",
  ACTIVE_USER_SESSION_PENDING: "GET_ACTIVE_USER_SESSION_PENDING",
  ACTIVE_USER_SESSION_SUCCESS: "GET_ACTIVE_USER_SESSION_SUCCESS",
  ACTIVE_USER_SESSION_FAILURE: "GET_ACTIVE_USER_SESSION_FAILURE",
};

// Authorization Actions
export const AUTH = {
  GET_SMARTLOGIC_BEARER_TOKEN: "GET_SMARTLOGIC_BEARER_TOKEN",
  GET_SMARTLOGIC_BEARER_TOKEN_PENDING: "GET_SMARTLOGIC_BEARER_TOKEN_PENDING",
  GET_SMARTLOGIC_BEARER_TOKEN_SUCCESS: "GET_SMARTLOGIC_BEARER_TOKEN_SUCCESS",
  GET_SMARTLOGIC_BEARER_TOKEN_FAILURE: "GET_SMARTLOGIC_BEARER_TOKEN_FAILURE"
};

export const CONSENT = {
  API_CONSENT_DATA: "API_CONSENT_RESULTS",
  API_CONSENT_RESULTS_PENDING: "API_CONSENT_RESULTS_PENDING",
  API_CONSENT_RESULTS_SUCCESS: "API_CONSENT_RESULTS_SUCCESS",
  API_CONSENT_RESULTS_FAILURE: "API_CONSENT_RESULTS_FAILURE",
};

export const SAVE_CONSENT = {
  API_SAVE_CONSENT: "API_SAVE_CONSENT",
  API_SAVE_CONSENT_PENDING: "API_SAVE_CONSENT_PENDING",
  API_SAVE_CONSENT_SUCCESS: "API_SAVE_CONSENT_SUCCESS",
  API_SAVE_CONSENT_FAILURE: "API_SAVE_CONSENT_FAILURE",
};

export const COMM_TEMPLATES = {
  API_COMM_TEMPLATES: "API_COMM_TEMPLATES",
  API_COMM_TEMPLATES_PENDING: "API_COMM_TEMPLATES_PENDING",
  API_COMM_TEMPLATES_SUCCESS: "API_COMM_TEMPLATES_SUCCESS",
  API_COMM_TEMPLATES_FAILURE: "API_COMM_TEMPLATES_FAILURE",
};

export const EXP_RECEIVED = {
  API_EXP_RECEIVED: "API_EXP_RECEIVED",
  API_EXP_RECEIVED_PENDING: "API_EXP_RECEIVED_PENDING",
  API_EXP_RECEIVED_SUCCESS: "API_EXP_RECEIVED_SUCCESS",
  API_EXP_RECEIVED_FAILURE: "API_EXP_RECEIVED_FAILURE",
};

export const DUPLICATE_EXP = {
  API_DUPLICATE_EXP: "API_DUPLICATE_EXP",
  API_DUPLICATE_EXP_PENDING: "API_DUPLICATE_EXP_PENDING",
  API_DUPLICATE_EXP_SUCCESS: "API_DUPLICATE_EXP_SUCCESS",
  API_DUPLICATE_EXP_FAILURE: "API_DUPLICATE_EXP_FAILURE",
};

export const CALLS = {
  API_CALLS: "API_CALLS",
  API_CALLS_PENDING: "API_CALLS_PENDING",
  API_CALLS_SUCCESS: "API_CALLS_SUCCESS",
  API_CALLS_FAILURE: "API_CALLS_FAILURE",
};

export const EMP_DESIGNATION = {
  API_EMP_DESIGNATION: "API_EMP_DESIGNATION",
  API_EMP_DESIGNATION_PENDING: "API_EMP_DESIGNATION_PENDING",
  API_EMP_DESIGNATION_SUCCESS: "API_EMP_DESIGNATION_SUCCESS",
  API_EMP_DESIGNATION_FAILURE: "API_EMP_DESIGNATION_FAILURE",
};


// Enitilement Actions
export const ENTITLEMENTS = {
  GET_ENTITLEMENTS: "GET_ENTITLEMENTS",
  API_GET_ENTITLEMENTS_PENDING: "API_GET_ENTITLEMENTS_PENDING",
  API_GET_ENTITLEMENTS_SUCCESS: "API_GET_ENTITLEMENTS_SUCCESS",
  API_GET_ENTITLEMENTS_FAILURE: "API_GET_ENTITLEMENTS_FAILURE",

  // Actions for checking whether the user is site admin/editor or not
  CHECK_SITE_ADMIN: "CHECK_SITE_ADMIN",
  API_CHECK_SITE_ADMIN_PENDING: "API_CHECK_SITE_ADMIN_PENDING",
  API_CHECK_SITE_ADMIN_SUCCESS: "API_CHECK_SITE_ADMIN_SUCCESS",
  API_CHECK_SITE_ADMIN_FAILURE: "API_CHECK_SITE_ADMIN_FAILURE",
}

export const TESTAPI = {
  API_TESTAPI_DATA: "API_TESTAPI_RESULTS",
  API_TESTAPI_RESULTS_PENDING: "API_TESTAPI_RESULTS_PENDING",
  API_TESTAPI_RESULTS_SUCCESS: "API_TESTAPI_RESULTS_SUCCESS",
  API_TESTAPI_RESULTS_FAILURE: "API_TESTAPI_RESULTS_FAILURE",
}
export const EXPERT_TYPES = {
  API_EXPERT_TYPES_DATA: "API_EXPERT_TYPES_RESULTS",
  API_EXPERT_TYPES_RESULTS_PENDING: "API_EXPERT_TYPES_RESULTS_PENDING",
  API_EXPERT_TYPES_RESULTS_SUCCESS: "API_EXPERT_TYPES_RESULTS_SUCCESS",
  API_EXPERT_TYPES_RESULTS_FAILURE: "API_EXPERT_TYPES_RESULTS_FAILURE",
};

export const APPROVE_TEMPLATES = {
  API_APPROVE_TEMPLATES_DATA: "API_APPROVE_TEMPLATES_RESULTS",
  API_APPROVE_TEMPLATES_RESULTS_PENDING: "API_APPROVE_TEMPLATES_RESULTS_PENDING",
  API_APPROVE_TEMPLATES_RESULTS_SUCCESS: "API_APPROVE_TEMPLATES_RESULTS_SUCCESS",
  API_APPROVE_TEMPLATES_RESULTS_FAILURE: "API_APPROVE_TEMPLATES_RESULTS_FAILURE",
  CLEAR_APPROVE_TEMPLATES: "CLEAR_APPROVE_TEMPLATES"
};

export const EDIT_TEMPLATE = {
  API_EDIT_TEMPLATE_DATA: "API_EDIT_TEMPLATE_RESULTS",
  API_EDIT_TEMPLATE_PENDING: "API_EDIT_TEMPLATE_PENDING",
  API_EDIT_TEMPLATE_SUCCESS: "API_EDIT_TEMPLATE_SUCCESS",
  API_EDIT_TEMPLATE_FAILURE: "API_EDIT_TEMPLATE_FAILURE",
};

export const OPEN_REQUESTS = {
  API_OPEN_REQUESTS_DATA: "API_OPEN_REQUESTS_RESULTS",
  API_OPEN_REQUESTS_RESULTS_PENDING: "API_OPEN_REQUESTS_RESULTS_PENDING",
  API_OPEN_REQUESTS_RESULTS_SUCCESS: "API_OPEN_REQUESTS_RESULTS_SUCCESS",
  API_OPEN_REQUESTS_RESULTS_FAILURE: "API_OPEN_REQUESTS_RESULTS_FAILURE",
};

export const REQUEST_DETAILS = {
  API_REQUEST_DETAILS_DATA: "API_REQUEST_DETAILS_RESULTS",
  API_REQUEST_DETAILS_RESULTS_PENDING: "API_REQUEST_DETAILS_RESULTS_PENDING",
  API_REQUEST_DETAILS_RESULTS_SUCCESS: "API_REQUEST_DETAILS_RESULTS_SUCCESS",
  API_REQUEST_DETAILS_RESULTS_FAILURE: "API_REQUEST_DETAILS_RESULTS_FAILURE"
};

export const REQUEST_TYPE = {
  API_REQUEST_TYPES_DATA: "API_REQUEST_TYPES_RESULTS",
  API_REQUEST_TYPES_RESULTS_PENDING: "API_REQUEST_TYPES_RESULTS_PENDING",
  API_REQUEST_TYPES_RESULTS_SUCCESS: "API_REQUEST_TYPES_RESULTS_SUCCESS",
  API_REQUEST_TYPES_RESULTS_FAILURE: "API_REQUEST_TYPES_RESULTS_FAILURE",
};

export const SAVE_INTERVIEW_REQUEST = {
  API_SAVE_INTERVIEW_REQUEST: "API_SAVE_INTERVIEW_REQUEST",
  API_SAVE_INTERVIEW_REQUEST_PENDING: "API_SAVE_INTERVIEW_REQUEST_PENDING",
  API_SAVE_INTERVIEW_REQUEST_SUCCESS: "API_SAVE_INTERVIEW_REQUEST_SUCCESS",
  API_SAVE_INTERVIEW_REQUEST_FAILURE: "API_SAVE_INTERVIEW_REQUEST_FAILURE",
};

export const EXTERNAL_NETWORK = {
  API_EXTERNAL_NETWORK_DATA: "API_EXTERNAL_NETWORK_RESULTS",
  API_EXTERNAL_NETWORK_RESULTS_PENDING: "API_EXTERNAL_NETWORK_RESULTS_PENDING",
  API_EXTERNAL_NETWORK_RESULTS_SUCCESS: "API_EXTERNAL_NETWORK_RESULTS_SUCCESS",
  API_EXTERNAL_NETWORK_RESULTS_FAILURE: "API_EXTERNAL_NETWORK_RESULTS_FAILURE",
};

export const TEMPLATE_FILTERS = {
  API_TEMPLATE_FILTERS_DATA: "API_TEMPLATE_FILTERS_RESULTS",
  API_TEMPLATE_FILTERS_RESULTS_PENDING: "API_TEMPLATE_FILTERS_RESULTS_PENDING",
  API_TEMPLATE_FILTERS_RESULTS_SUCCESS: "API_TEMPLATE_FILTERS_RESULTS_SUCCESS",
  API_TEMPLATE_FILTERS_RESULTS_FAILURE: "API_TEMPLATE_FILTERS_RESULTS_FAILURE",
};

export const SHARE_VIA_EMAIL = {
  API_SHARE_VIA_EMAIL_DATA: "API_SHARE_VIA_EMAIL_RESULTS",
  API_SHARE_VIA_EMAIL_RESULTS_PENDING: "API_SHARE_VIA_EMAIL_RESULTS_PENDING",
  API_SHARE_VIA_EMAIL_RESULTS_SUCCESS: "API_SHARE_VIA_EMAIL_RESULTS_SUCCESS",
  API_SHARE_VIA_EMAIL_RESULTS_FAILURE: "API_SHARE_VIA_EMAIL_RESULTS_FAILURE",
  API_SHARE_VIA_EMAIL_RESULTS_RESET: "API_SHARE_VIA_EMAIL_RESULTS_RESET",
};

export const EXP_REC_FILTERS = {
  API_EXP_REC_FILTERS_DATA: "API_EXP_REC_FILTERS_RESULTS",
  API_EXP_REC_FILTERS_RESULTS_PENDING: "API_EXP_REC_FILTERS_RESULTS_PENDING",
  API_EXP_REC_FILTERS_RESULTS_SUCCESS: "API_EXP_REC_FILTERS_RESULTS_SUCCESS",
  API_EXP_REC_FILTERS_RESULTS_FAILURE: "API_EXP_REC_FILTERS_RESULTS_FAILURE",
};

export const CALLS_FILTERS = {
  API_CALLS_FILTERS_DATA: "API_CALLS_FILTERS_RESULTS",
  API_CALLS_FILTERS_RESULTS_PENDING: "API_CALLS_FILTERS_RESULTS_PENDING",
  API_CALLS_FILTERS_RESULTS_SUCCESS: "API_CALLS_FILTERS_RESULTS_SUCCESS",
  API_CALLS_FILTERS_RESULTS_FAILURE: "API_CALLS_FILTERS_RESULTS_FAILURE",
};

export const DISPUTE_CALL = {
  API_DISPUTE_CALL: "API_DISPUTE_CALL",
  API_DISPUTE_CALL_RESULTS_PENDING: "API_DISPUTE_CALL_RESULTS_PENDING",
  API_DISPUTE_CALL_RESULTS_SUCCESS: "API_DISPUTE_CALL_RESULTS_SUCCESS",
  API_DISPUTE_CALL_RESULTS_FAILURE: "API_DISPUTE_CALL_RESULTS_FAILURE",
  API_CLEAR_DISPUTE_CALL: "API_CLEAR_DISPUTE_CALL"
};

export const CANCEL_CALL = {
  API_CANCEL_CALL: "API_CANCEL_CALL",
  API_CANCEL_CALL_RESULTS_PENDING: "API_CANCEL_CALL_RESULTS_PENDING",
  API_CANCEL_CALL_RESULTS_SUCCESS: "API_CANCEL_CALL_RESULTS_SUCCESS",
  API_CANCEL_CALL_RESULTS_FAILURE: "API_CANCEL_CALL_RESULTS_FAILURE",
  API_CLEAR_CANCEL_CALL: "API_CLEAR_CANCEL_CALL"
};

export const CHARGE_CODE = {
  API_CHARGE_CODE_DATA: "API_CHARGE_CODE_RESULTS",
  API_CHARGE_CODE_RESULTS_PENDING: "API_CHARGE_CODE_RESULTS_PENDING",
  API_CHARGE_CODE_RESULTS_SUCCESS: "API_CHARGE_CODE_RESULTS_SUCCESS",
  API_CHARGE_CODE_RESULTS_FAILURE: "API_CHARGE_CODE_RESULTS_FAILURE",
};

export const CHARGE_CODE_DETAILS = {
  API_CHARGE_CODE_DETAILS_DATA: "API_CHARGE_CODE_DETAILS_RESULTS",
  API_CHARGE_CODE_DETAILS_RESULTS_PENDING: "API_CHARGE_CODE_DETAILS_RESULTS_PENDING",
  API_CHARGE_CODE_DETAILS_RESULTS_SUCCESS: "API_CHARGE_CODE_DETAILS_RESULTS_SUCCESS",
  API_CHARGE_CODE_DETAILS_RESULTS_FAILURE: "API_CHARGE_CODE_DETAILS_RESULTS_FAILURE",
};

export const UPLOAD_S3 = {
  GET_UPLOAD_S3: "GET_UPLOAD_S3",
  GET_UPLOAD_S3_PENDING: "GET_UPLOAD_S3_PENDING",
  GET_UPLOAD_S3_SUCCESS: "GET_UPLOAD_S3_SUCCESS",
  GET_UPLOAD_S3_FAILURE: "GET_UPLOAD_S3_FAILURE",
  FETCH_PRESIGNURL_PENDING: "FETCH_PRESIGNURL_PENDING",
  FETCH_PRESIGNURL_FAILURE: "FETCH_PRESIGNURL_FAILURE",
  FETCH_PRESIGNURL_SUCCESS: "FETCH_PRESIGNURL_SUCCESS",
}

export const DELETE_FILE = {
  API_DELETE_FILE: "API_DELETE_FILE",
  API_DELETE_FILE_PENDING: "API_DELETE_FILE_PENDING",
  API_DELETE_FILE_SUCCESS: "API_DELETE_FILE_SUCCESS",
  API_DELETE_FILE_FAILURE: "API_DELETE_FILE_FAILURE",
};

export const RENAME_FILE = {
  API_RENAME_FILE: "API_RENAME_FILE",
  API_RENAME_FILE_PENDING: "API_RENAME_FILE_PENDING",
  API_RENAME_FILE_SUCCESS: "API_RENAME_FILE_SUCCESS",
  API_RENAME_FILE_FAILURE: "API_RENAME_FILE_FAILURE",
};

export const PROFILE_ACTION = {
  API_PROFILE_ACTION: "API_PROFILE_ACTION",
  API_PROFILE_ACTION_PENDING: "API_PROFILE_ACTION_PENDING",
  API_PROFILE_ACTION_SUCCESS: "API_PROFILE_ACTION_SUCCESS",
  API_PROFILE_ACTION_FAILURE: "API_PROFILE_ACTION_FAILURE",
};

export const SAVE_CALL_SLOTS = {
  API_SAVE_CALL_SLOTS: "API_SAVE_CALL_SLOTS",
  API_SAVE_CALL_SLOTS_PENDING: "API_SAVE_CALL_SLOTS_PENDING",
  API_SAVE_CALL_SLOTS_SUCCESS: "API_SAVE_CALL_SLOTS_SUCCESS",
  API_SAVE_CALL_SLOTS_FAILURE: "API_SAVE_CALL_SLOTS_FAILURE",
};