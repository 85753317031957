import React, { PureComponent } from "react";
import BreadCrumbs from "../../components/shared/BreadCrumbs";
import { Radio } from 'semantic-ui-react';
import { RequestStepperForm } from "components/RequestStepperForm";
import { Layout } from "containers/Layout";
import './ExpertRequest.scss';
import FormProvider from 'context/form-context';
import { getUserProfilePictures } from "api/getUserProfilePictures";
import { getUserId } from "utils/auth/auth";
import { globalAdobeAnalytics, getStaffId } from '../../analytics.js';

export class ExpertContainer extends PureComponent {

    state = {
        selectedOption: 'expInterviews',
        activepath: window?.location?.pathname,
        editData: this.props.location.state,
        hasFormLoaded: false
    };

    handleChange = (e, { value }) => {
        this.setState({ selectedOption: value });
    };

    componentDidMount() {
        this.setState({ activepath: window?.location?.pathname, editData: this.props.location.state });
        this.handleLoad();
        this.fetchLoggedInUserEmail();
    }

    handleLoad = () => {
        if (!sessionStorage.getItem('hasFormLoaded')) {
            sessionStorage.setItem('hasFormLoaded', 'true');
        } else {
            if (window?.location?.pathname === '/ExpertRequest/initiate')
                globalAdobeAnalytics('EEH Request Form', 'EEH Request Form');
            if (window?.location?.pathname === '/ExpertRequest/edit')
                globalAdobeAnalytics('EEH Edit Request Form', 'EEH Request Form');
        }
        this.setState({ hasFormLoaded: true });
    };

    fetchLoggedInUserEmail = async () => {
        const hrid = await getUserId();
        const resp = await getUserProfilePictures([hrid]);
        if (resp?.length > 0) {
            getStaffId(resp[0]?.email);
        }
    }

    render() {
        const pathsArr = [{
            active: false,
            pathname: '/',
            name: 'Home'
        },
        {
            active: false,
            pathname: '/InitiateExpertRequest',
            name: 'Initiate Expert Interview or Survey Request Form'
        }
        ];
        return (
            <Layout id="chat" layoutClass="layout__mynavigator">
                <FormProvider>
                    <div className="expertRequest__page">
                        <div className="expertRequest__pagehead">
                            <BreadCrumbs paths={pathsArr} />
                            {this.state.activepath.includes('edit') ?
                                <div className="expertRequest--heading">Edit Request Details</div>
                                : <>
                                    <div className="expertRequest--heading">Initiate Expert Interview or Survey Request</div>
                                    <div className="expertRequest__subheading">
                                        Initiate your request for external expert interviews and surveys using this form. For details on vendors and pricing, please refer to the <span className="expertRequest--subheadinglink" onClick={() => window.open('https://internal.bcg.com/Microsites/Functions/External-Expert-Networks', "_blank")}>EEN microsite</span>.
                                    </div>
                                    <div className="expertRequest--text">
                                        Please select a service for which you would like to launch the request
                                    </div>
                                    <div className="expertRequest__options">
                                        <div>
                                            <Radio
                                                label='Expert Interviews'
                                                name='radioGroup'
                                                value='expInterviews'
                                                checked={this.state.selectedOption === 'expInterviews'}
                                                onChange={this.handleChange}
                                            />
                                        </div>
                                        <div>
                                            <Radio
                                                label='Expert Surveys'
                                                name='radioGroup'
                                                value='expSurveys'
                                                id="expSurveys"
                                                checked={this.state.selectedOption === 'expSurveys'}
                                                onChange={this.handleChange}
                                            />
                                        </div>
                                    </div>
                                </>}
                        </div>
                        <RequestStepperForm selForm={this.state.selectedOption} editData={this.state.editData} freshView={true} />
                    </div>
                </FormProvider>
            </Layout>
        )
    }
}

export const ExpertRequest = (ExpertContainer);