import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { selectors as peopleResultsSelectors } from "redux/reducers/peopleresults/peopleresults_reducer";
import { withRouter } from "react-router-dom";
import Image from "components/shared/Image";
import "./Banner.scss";
import handIcon from "assets/images/waving_hand.svg";
import { getStaffId, initiateRequestFormClickAnalytics, globalAdobeAnalytics } from '../../analytics.js';
import { getUserProfilePictures } from "api/getUserProfilePictures";
import { getUserId } from "utils/auth/auth";

const Banner = ({ history, bannerText, bannerSubText }) => {

  const peopledata = useSelector(peopleResultsSelectors.getPeopleResults);
  const handleButtonClick = () => {
    initiateRequestFormClickAnalytics('EEH-Request Form Banner Button Click', 'Initiate Expert Request', 'EEH Home Page');
    globalAdobeAnalytics('EEH Request Form', 'EEH Home Page');
    history.push("/ExpertRequest/initiate");
  };

  useEffect(() => {
    fetchLoggedInUserEmail
  }, []);

  const fetchLoggedInUserEmail = async () => {
    const hrid = await getUserId();
    const resp = await getUserProfilePictures([hrid]);
    if (resp?.length > 0) {
      getStaffId(resp[0]?.email);
    }
  }
  return (
    <div className="homebanner__wrap">
      <div className="homebanner__content">
        <div className="username">Hi {peopledata[0]?.firstName} <Image src={handIcon} /></div>
        <span className="heading">{bannerText}</span>
        <p className="details">{bannerSubText}</p>
      </div>
      <button className="homebanner__initiate_exp_btn" onClick={() => handleButtonClick()}>
        Initiate Expert Request
      </button>
    </div>
  );
};

export default withRouter(Banner);
