import React, { useState, useEffect, useContext } from 'react';
import './RequestStepperForm.scss';
import infoIcon from '../../assets/images/info_icon.png';
import { useDispatch, useSelector } from 'react-redux';
import { selectors as peopleSelector } from "../../redux/reducers/peopleresults/peopleresults_reducer";
import { ExpertInterviewsForm } from 'components/ExpertInterviewsForm';
import { getUserId } from "utils/auth/auth";
import { getUserProfilePictures } from "api/getUserProfilePictures";
import { getStaffId, initiateSubmitClickAnalytics } from '../../analytics.js';
import { getPeopleAPIData } from "redux/actions/peopleresults/peopleresults.actions";
import { FormContext } from 'context/form-context';
import { selectors as saveInterviewRequestSelector } from "redux/reducers/saveRequest/saveRequest_reducer";
import { getSaveInterviewAPIData } from 'redux/actions/saveInterviewRequest/saveInterviewRequest.actions';
import { getFromLocalStorageAsJSON, removeFromLocalStorage } from 'utils/localStorage/localStorage';
import { EXPERT_INTERVIEW_FIELDS, EXPERT_SURVEY_FIELDS } from 'assets/static_data/form-fields';
import { AlertPopup } from 'components/shared/AlertPopup';

export const RequestStepperForm = ({ selForm, freshView, editData }) => {
    const dispatch = useDispatch();
    const peopleData = useSelector(peopleSelector.getPeopleResults);
    const formFieldsData = selForm === 'expSurveys' ? EXPERT_SURVEY_FIELDS : EXPERT_INTERVIEW_FIELDS;
    const saveRequest = useSelector(saveInterviewRequestSelector.getSaveRequestResults);
    const [saveAPICompletedFlag, setSaveAPICompleted] = useState(freshView);
    const [submitBtnClickedFlag, setSubmitBtnClicked] = useState(false);
    useEffect(() => {
        if (!saveAPICompletedFlag) {
            if (saveRequest?.error) {
                setSubmitBtnClicked(false);
                setAlert({
                    type: 'error',
                    requestId: 0,
                    requestType: selForm === 'expSurveys' ? 'Survey' : 'Interview',
                    projectType: '',
                    show: true
                });
            }
            if (saveRequest?.interviewRequestId || saveRequest?.surveyRequestId) {
                setSubmitBtnClicked(false);
                setAlert({
                    type: 'success',
                    requestType: selForm === 'expSurveys' ? 'Survey' : 'Interview',
                    requestId: selForm === 'expSurveys' ? saveRequest.surveyRequestId : saveRequest.interviewRequestId,
                    projectType: saveRequest.projectType ? saveRequest.projectType : '',
                    show: true,
                    newRequest: formHandlers.formView == 'add'
                });
                dispatch(getSaveInterviewAPIData(null));
            }
        }
    }, [saveRequest, saveAPICompletedFlag]);

    const formHandlers = useContext(FormContext);
    const [alert, setAlert] = useState({});

    useEffect(() => {
        fetchUserDetails();
    }, []);

    useEffect(() => {
        // formHandlers.resetForm();
        if (editData) {
            formHandlers.toggleFormValues('formNotTouched', false);
            formHandlers.toggleFormView('edit', editData);
        } else {
            formHandlers.toggleFormView('add', {});
        }
    }, [editData, selForm]);

    const fetchUserDetails = async () => {
        const hrid = await getUserId();
        const resp = await getUserProfilePictures([hrid]);
        if (resp?.length > 0) {
            getStaffId(resp[0]?.email);
        }
        dispatch(getPeopleAPIData(hrid));
    }

    useEffect(() => {
        peopleData[0]?.preferredName ? formHandlers.toggleFormValues('requestorName', peopleData[0].preferredName) : '';
        peopleData[0]?.id ? formHandlers.toggleFormValues('hrId', peopleData[0].id) : '';
        peopleData[0]?.email ? formHandlers.toggleFormValues('requestor', peopleData[0].email) : '';
        if (!formHandlers.fieldsData['phoneNo']) {
            peopleData[0]?.phone ? formHandlers.toggleFormValues('phoneNo', peopleData[0].phone) : '';
        }
        peopleData[0]?.hostOfficeLocation?.name ? formHandlers.toggleFormValues('office', peopleData[0].hostOfficeLocation.name) : '';
        peopleData[0]?.hostOfficeLocation?.timezone ? formHandlers.toggleFormValues('timezone', peopleData[0].hostOfficeLocation.timezone) : '';
    }, [peopleData, selForm]);

    const scrollToInvalidHandler = (field) => {
        if (field) {
            const InvalidInput = document.getElementsByName(field);
            if (InvalidInput[0]) {
                if (field.toLowerCase().includes('date')) {
                    InvalidInput[0].parentElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                }
                else {
                    InvalidInput[0].scrollIntoView({ behavior: 'smooth', block: 'center' });
                }

            } else {
                window.scrollTo({ top: 0, behavior: "smooth" });
            }
        }
    }

    const submitFormRequest = () => {
        if (!submitBtnClickedFlag) {
            let selectedForm = selForm === 'expSurveys' ? 'Expert Surveys' : 'Expert Interviews';
            // if (getFromLocalStorageAsJSON('hasDetailsLoaded') === 'true') {
            //     initiateSubmitClickAnalytics('EEH Request Form', selectedForm, 'Initiate Expert Request', 'EEH Request Details Page');
            // }
            // else {
            //     initiateSubmitClickAnalytics('EEH Request Form', selectedForm, 'Initiate Expert Request', 'EEH Home Page');
            // }
            setSubmitBtnClicked(true);
            const formFieldSavedValues = formHandlers.fieldsData;
            let errorFlag = false, errorField = '';
            formFieldsData.forEach((field) => {
                if (field.error && !(field.id == 'providers' && formHandlers.formView == 'edit')) {
                    formHandlers.logInvalidFields(field.id);
                    if (!formFieldSavedValues[field.id] || formFieldSavedValues[field.id]?.length == 0 || formHandlers.invalidFields.indexOf(field.id) > -1) {
                        errorFlag = true;
                        if (!errorField) {
                            errorField = field.id;
                        }
                    }
                }
            });
            let invalidFieldsAvailable = ((selForm === 'expSurveys' && formHandlers.invalidFields.length == 1 && formHandlers.invalidFields.includes("expertType")) || (formHandlers.invalidFields.length == 0)) ? false : true;
            if (formFieldSavedValues.formNotTouched) {
                setSubmitBtnClicked(false);
                window.scrollTo({ top: '90px', behavior: "smooth" });
            } else if (invalidFieldsAvailable || errorFlag) {
                setSubmitBtnClicked(false);
                scrollToInvalidHandler(errorField);
            } else {
                setSaveAPICompleted(false);
                let requestData = {};
                if (selForm === 'expSurveys') {
                    requestData = {
                        "chargeCode": formFieldSavedValues.chargeCode,
                        "requestTitle": formFieldSavedValues.requestTitle,
                        "projectType": getProviderData(formFieldSavedValues.projectType),
                        "caseTeamMembers": getCaseTeamMembersDetails(formFieldSavedValues),
                        "requestDescription": formFieldSavedValues.requestDescription,
                        "incQuery": formFieldSavedValues.incQuery,
                        "surveyRespondentsCount": Number(formFieldSavedValues.totalSurvey),
                        "externalNetworks": getExpertNetworkDetails(formFieldSavedValues.resourceManager, formFieldSavedValues.providers),
                        "surveyStartDate": formatDateValue(formFieldSavedValues.surveyStartDate),
                        "surveyEndDate": formatDateValue(formFieldSavedValues.surveyEndDate),
                        "attachmentsFolderName": formFieldSavedValues.attachmentsFolderName
                    };

                } else {
                    requestData = {
                        "chargeCode": formFieldSavedValues.chargeCode,
                        "requestTitle": formFieldSavedValues.requestTitle,
                        "projectType": getProviderData(formFieldSavedValues.projectType),
                        "caseTeamMembers": getCaseTeamMembersDetails(formFieldSavedValues),
                        "requestDescription": formFieldSavedValues.requestDescription,
                        "clientIndustries": formFieldSavedValues.clientIndustry,
                        "offLimitCompanies": formFieldSavedValues.offLimitCompanies,
                        "expertTypes": getExpertTypeDetails(formFieldSavedValues.expertType),
                        "interviewAnticipatedCount": Number(formFieldSavedValues.interviewAnticipatedCount),
                        "externalNetworks": getExpertNetworkDetails(formFieldSavedValues.resourceManager, formFieldSavedValues.providers, formFieldSavedValues.externalNetworks),
                        "interviewAnticipatedStartDate": formFieldSavedValues.id ? formFieldSavedValues.interviewStartDate : formatDateValue(formFieldSavedValues.interviewStartDate),
                        "interviewAnticipatedEndDate": formatDateValue(formFieldSavedValues.interviewEndDate),
                        "attachmentsFolderName": formFieldSavedValues.attachmentsFolderName
                    };
                }
                if (formHandlers.formView == 'edit') {
                    requestData.attachmentsDetails = formFieldSavedValues.attachmentsDetails ? formFieldSavedValues.attachmentsDetails : [];
                }

                initiateSubmitClickAnalytics('EEH Request Form', selectedForm, 'Initiate Expert Request', 'EEH Request Form');
                dispatch(getSaveInterviewAPIData({ data: requestData, flag: selForm === 'expSurveys', requestId: formFieldSavedValues.id }));
            }
        }
    }
    const getProviderData = (data) => {
        let returnData = { id: '', type: '' };
        const projectTypeRecord = formHandlers.dropdownData.projectType.filter((item) => item.name === data);
        if (projectTypeRecord?.length > 0) {
            returnData = { id: projectTypeRecord[0].id, "type": projectTypeRecord[0].type };
        }
        return returnData;
    }
    const getCaseTeamMembersDetails = (data) => {
        let caseTeamMembers = data.caseTeamMembers?.length > 0 ? [...data.caseTeamMembers] : [];

        if (formHandlers.formView != 'edit') {
            caseTeamMembers.push({
                "hrId": data.hrId,
                "memberName": data.requestorName,
                "memberIsdCode": data.phoneNo && data.phoneNo[0] ? data.phoneNo[0] : '',
                "memberPhoneNumber": data.phoneNo && data.phoneNo[1] ? data.phoneNo[1] : '',
                "memberEmail": data.requestor,
                "memberHomeOffice": data.office,
                "memberTimezone": data.timezone,
                "memberType": "requestor",
                "expertTypes": []
            });
        } else {
            const duplicateMember = caseTeamMembers.findIndex((c) => c.memberType == "requestor");
            caseTeamMembers[duplicateMember].memberIsdCode = data.phoneNo[0] ? data.phoneNo[0] : '';
            caseTeamMembers[duplicateMember].memberPhoneNumber = data.phoneNo[1] ? data.phoneNo[1] : '';
        }

        if (data.caseTeam?.length > 0) {
            data.caseTeam.forEach((member) => {
                const duplicateMember = caseTeamMembers.findIndex((c) => c.hrId == member.id);
                if (duplicateMember == -1) {
                    caseTeamMembers.push({
                        "hrId": member.id,
                        "memberEmail": member.email,
                        "memberType": "case_team_member",
                        "expertTypes": []
                    });
                }
            });
        }
        if (data.expertContact?.length > 0) {
            data.expertContact.forEach((contact) => {
                const typeRecord = formHandlers.dropdownData.expertType.filter((item) => item.name === contact.expert);
                let expertDetail = {};
                if (typeRecord[0]?.id) {
                    expertDetail = { "expertTypeId": typeRecord[0].id, "expertTypeName": typeRecord[0].name, "type": typeRecord[0].type };
                } else {
                    expertDetail = { "expertTypeName": contact.expert, "type": "custom" };
                }
                if (contact.member?.length > 0) {
                    contact.member.forEach((person) => {
                        const existingTeamMember = caseTeamMembers.findIndex((mem) => mem.hrId == person.id);
                        if (existingTeamMember > -1) {
                            caseTeamMembers[existingTeamMember]['expertTypes'].push(expertDetail);
                        } else {
                            caseTeamMembers.push({
                                "hrId": person.id,
                                "memberEmail": person.email,
                                "memberType": "case_team_member",
                                "expertTypes": [expertDetail]
                            });
                        }
                    })

                }
            });
        }

        return caseTeamMembers;
    }
    const getExpertTypeDetails = (data) => {
        let expertTypeArray = [];
        data.forEach((type) => {
            const typeRecord = formHandlers.dropdownData.expertType.filter((item) => item.name === type);
            let expertDetail = {};
            if (typeRecord[0]?.id) {
                expertDetail = { "expertTypeId": typeRecord[0].id, "type": typeRecord[0].type };
            } else {
                expertDetail = { "expertTypeName": type, "type": "custom" };
            }
            expertTypeArray.push(expertDetail);
        });
        return expertTypeArray;
    }
    const getExpertNetworkDetails = (dataRM, dataP, previousData) => {
        let formatedData = previousData?.length > 0 ? [...previousData] : [];
        if (dataRM) {
            dataRM.forEach((elem) => {
                const networkRecord = formHandlers.dropdownData.providers.filter((item) => item.providerName === elem.resManager);
                formatedData.push({
                    "id": networkRecord[0].id,
                    "researchManagerEmail": elem.email
                });
            });
        }
        if (dataP?.length > 0 && (dataP?.length != dataRM?.length ||
            !dataRM || dataRM?.length == 0)) {
            dataP.forEach((pro) => {
                let available = [];
                const networkRecord = formHandlers.dropdownData.providers.filter((item) => item.providerName === pro);
                if (dataRM?.length > 0) {
                    available = dataRM.filter(item => item?.resManager == pro);
                }
                if (available.length == 0) {
                    formatedData.push({
                        "id": networkRecord[0].id,
                        "researchManagerEmail": ''
                    });
                }
            });
        }
        return formatedData;
    }
    const formatDateValue = (dateValue) => {
        let returnValue = '';
        if (typeof (dateValue) == "string") {
            return dateValue;
        }
        if (dateValue) {
            const year = dateValue.getFullYear();
            const month = (dateValue.getMonth() + 1).toString().length == 1 ? '0' + (dateValue.getMonth() + 1) : (dateValue.getMonth() + 1);
            const date = dateValue.getDate().toString().length == 1 ? '0' + dateValue.getDate() : dateValue.getDate();
            returnValue = year + '-' + month + '-' + date;
        }
        return returnValue;
    }

    const closeAlertPopupHandler = () => {
        setAlert({});
    }

    return (
        <>
            {alert.show != undefined && alert.show != null && <AlertPopup
                open={alert.show}
                type={alert.type}
                errorMsg={alert.errorMsg}
                isNewRequest={alert.newRequest}
                requestId={alert.requestId}
                requestType={alert.requestType}
                projectType={alert.projectType}
                closeAlertHandler={closeAlertPopupHandler} />}

            <div className='requeststepperform__wrap'>
                <div className='requeststepperform__rightsection'>
                    {selForm === 'expSurveys' &&
                        <div className="survey-alert">
                            <img alt="infoIcon" src={infoIcon}></img>
                            <div>Please note that your survey request will be addressed by selected providers via email and managed offline for the duration of EEH trial.</div>
                        </div>
                    }
                    <ExpertInterviewsForm isSurveyForm={selForm === 'expSurveys'} />
                </div>
            </div >
            <div className="form-footer">
                <button className='default-button' onClick={() => submitFormRequest()}>Submit</button>
            </div>
        </>
    );
};
