import { EXPERT_TYPES } from 'redux/constants';

//  People CCO actions
export const getExpertTypeAPIData = () => ({
    type: EXPERT_TYPES.API_EXPERT_TYPES_DATA,
});

export const getExpertTypeAPIDataPending = () => ({
    type: EXPERT_TYPES.API_EXPERT_TYPES_RESULTS_PENDING
});

export const getExpertTypeAPIDataSuccess = (payload) => ({
    type: EXPERT_TYPES.API_EXPERT_TYPES_RESULTS_SUCCESS,
    payload
});

export const getExpertTypeAPIDataFailure = (error) => ({
    type: EXPERT_TYPES.API_EXPERT_TYPES_RESULTS_FAILURE,
    payload: { error: error?.toString() }
});
