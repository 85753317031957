import { CALLS_FILTERS } from "redux/constants";
export const name = "callsFilters";

// initial state
export const initialState = {
    loading: true,
    error: false,
    errorMessage: "",
    callsFilters: {},
    noRecordFound: false,
};

// reducer selectors
export const selectors = {
    getLoading: state => state[name].loading,
    getError: state => state[name].error,
    getErrorMessage: state => state[name].errorMessage,
    getCallsFiltersResults: (state) => state[name]?.callsFilters ? state[name]?.callsFilters : "",
    getNoRecordFound: state => state[name].noRecordFound,
};

// reducer
export function reducer(state = initialState, action) {
    switch (action.type) {
        case CALLS_FILTERS.API_CALLS_FILTERS_RESULTS_FAILURE:
            return {
                ...state,
                loading: false,
                error: true,
                errorMessage: action.payload?.data,
            };
        case CALLS_FILTERS.API_CALLS_FILTERS_RESULTS_PENDING:
            return {
                ...state,
                loading: true,
                noRecordFound: false,
                error: false,
                errorMessage: '',
            };
        case CALLS_FILTERS.API_CALLS_FILTERS_RESULTS_SUCCESS:
            return {
                ...state,
                loading: false,
                noRecordFound: action.payload?.length <= 0,
                callsFilters: action?.payload,
            };
        default:
            return state;
    }
}