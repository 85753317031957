//////////// Importing Constant Files ////////
// import LABELS from "labels";

// Global constants

const globalConstants = {
  ACTIONS: {
    GLOBAL_SAGA_FAILURE: "GLOBAL_SAGA_FAILURE",
    GLOBAL_MODULE_FAILURE: "GLOBAL_MODULE_FAILURE"
  },
  TAB: "tab",
  TAB1: "tab1",
  TAB2: "tab2",
  TAB3: "tab3",
  ENTITLEMENTS: {
    KNOWLEDGE_CONTRIBUTIONS: "KNOWLEDGE_CONTRIBUTIONS",
    CASE_PAGES: "CASE_PAGES",
    VIEW: "VIEW",
    CREATE_EDIT_DRAFT: "CREATE_EDIT_DRAFT",
    PREVIEW_DOWNLOAD: "PREVIEW_DOWNLOAD",
    PREVIEW_DOWNLOAD_R: "PREVIEW_DOWNLOAD_R",
    EDIT_PUBLISHED_CONTENT: "EDIT_PUBLISHED_CONTENT",
    PUBLISH_CONTENT: "PUBLISH_CONTENT",
    SHOW_BUNDLE: "SHOW_BUNDLE",
    PRODUCTID: "PRODUCTID",
    ALL: "ALL",
    VIEW_CASE_DETAIL: "VIEW_CASE_DETAIL",
    VIEW_CLIENT_INFO: "VIEW_CLIENT_INFO",
    VIEW_BUDGET: "VIEW_BUDGET",
    EDIT: "EDIT",
    EDIT_TOPIC_TAGS: "EDIT_TOPIC_TAGS",
    VIEW_CASE_MATERIALS: "VIEW_CASE_MATERIALS",
  },
  API_STATUS: {
    START: "Start",
    PENDING: "Pending",
    COMPLETED: "Completed",
    ERROR: "Error"
  },
  ERROR_401: "401",
  ERROR_IE: "ie11",
  ERROR_ENTITLEMENT_API: "entitlementapi",
  DOCVIZ_EXTNS: [
    "doc",
    "docx",
    "pdf",
    "ppt",
    "pptx"
  ],
  TBDBS: {
    SEARCH_SUBJECT: "SEARCH_SUBJECT",
    SEARCH_FPA_IPA_INTERNAL: "SEARCH_FPA_IPA_INTERNAL",
    FPA: "FPA",
    IPA: "IPA",
    BCG_INTERNAL: "BCG_INTERNAL"
  },
}

export default globalConstants;