import { CONSENT } from 'redux/constants';

//  People CCO actions
export const getConsentAPIData = (searchString, nextPageToken) => ({
    type: CONSENT.API_CONSENT_DATA,
    payload: {
        searchString: searchString || '',
        nextPageToken
    }
});

export const getConsentAPIDataPending = () => ({
    type: CONSENT.API_CONSENT_RESULTS_PENDING
});

export const getConsentAPIDataSuccess = (payload) => ({
    type: CONSENT.API_CONSENT_RESULTS_SUCCESS,
    payload
});

export const getConsentAPIDataFailure = (error) => ({
    type: CONSENT.API_CONSENT_RESULTS_FAILURE,
    payload: { error: error?.toString() }
});
