import React, { useState, useEffect, useRef } from 'react';
import DownArrow from "assets/images/down_arrow.svg";
import CrossIcon from "assets/images/cross_icon.svg";
import {ReactComponent as CheckMarkImg} from "assets/images/checkmark.svg";
import {ReactComponent as CancelImg} from "assets/images/cancel-cross.svg";
import AddMoreIcon from "assets/images/add_more.svg";
import { Checkbox, Image, Label } from 'semantic-ui-react';
import './DropdownField.scss';

export const DropdownField = ({ data, selectedValue, placeholderTxt, isDisabled, isMultiSelect, isInvalidField, isAddNewAvailable, onSelectHandler, onFocusOutHandler, fieldId, disabledValues }) => {
    const [placeHolder, setPlaceHolder] = useState(placeholderTxt);
    const [showOthersInput, setOthersInputShow] = useState(false);
    const popupRef = useRef(null);
    const othersFieldRef = useRef();

    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [selectedCheckBoxes, setSelectedCheckBoxes] = useState([]);
    const [isRemovingItem, setIsRemovingItem] = useState(false);
    const [outsideClickFlag, setOutsideClickFlag] = useState(false);

    useEffect(() => {
        if (outsideClickFlag) {
            onFocusOutHandler(selectedCheckBoxes);
            setOutsideClickFlag(false);
        }
    }, [outsideClickFlag, selectedCheckBoxes]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (popupRef.current && !popupRef.current.contains(event.target)) {
                setIsPopupOpen(false);
                setOutsideClickFlag(true);
                setOthersInputShow(false);
            }
        };

        const handleMouseDown = (event) => {
            // Check if the down arrow image was clicked
            if (!event.target.classList.contains('arrow-img') && !event.target.classList.contains('dropdown--textbox')) {
                handleClickOutside(event);
            }
            if(!event.target.classList.contains(fieldId)){
                handleClickOutside(event);
            }
        };
        document.addEventListener("mousedown", handleMouseDown);

        return () => {
            document.removeEventListener("mousedown", handleMouseDown);
        };
    }, [isRemovingItem]);
    useEffect(() => {
        renderSelectedCheckBoxes();
    }, [selectedCheckBoxes]);

    useEffect(() => {
        if (showOthersInput) {
            othersFieldRef.current.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'end' });
        }
    }, [showOthersInput]);

    const handleSelectProvider = (event) => {
        const { outerText } = event.target;
        setIsPopupOpen(false);
        setOthersInputShow(false);
        onSelectHandler(outerText);
    }
    const handleCheckBoxChange = (event, selection) => {
        let updatedSelectedCheckBoxes = [];
        if (selection.checked) {
            updatedSelectedCheckBoxes = [...selectedCheckBoxes, selection.label]
            setSelectedCheckBoxes(prev => [...prev, selection.label]);
        } else {
            updatedSelectedCheckBoxes = selectedCheckBoxes.filter(item => item !== selection.label);
            setSelectedCheckBoxes(prev => prev.filter(item => item !== selection.label));
        }
        updatedSelectedCheckBoxes.length > 0 ? setPlaceHolder('') : setPlaceHolder(placeholderTxt);
        onSelectHandler(updatedSelectedCheckBoxes);
    }

    const renderSelectedCheckBoxes = () => {
        return selectedCheckBoxes.map((item, index) => (
            <Label key={index}>
                {item}
                <Image src={CrossIcon} alt="crossIcon"
                    onClick={() => handleRemoveItem(item)}
                />
            </Label>
        ));
    }
    const handleRemoveItem = (incomingItem) => {
        const updatedItems = selectedCheckBoxes.filter(selectedItem => selectedItem !== incomingItem);
        if(updatedItems.length == 0 && selectedCheckBoxes.length > 0){
            setPlaceHolder(placeholderTxt);
        } else {
            setPlaceHolder('');
        }
        setSelectedCheckBoxes(updatedItems);
        // setIsPopupOpen(false);
        setIsRemovingItem(true);
        setOthersInputShow(false);
        onSelectHandler(updatedItems);
    };
    const onOthersClickHandler = () => {
        setOthersInputShow(!showOthersInput);
    }
    const onCustomExpertSaveHandler = () => {
        setOthersInputShow(!showOthersInput);
        const fieldValue = document.getElementById('customValue').value;
        data.push({name: fieldValue, isChecked: true});
        let updatedSelectedCheckBoxes = [];
        setPlaceHolder('');
        if (fieldValue) {
            updatedSelectedCheckBoxes = [...selectedCheckBoxes, fieldValue]
            setSelectedCheckBoxes(prev => [...prev, fieldValue]);
        }
        onSelectHandler(updatedSelectedCheckBoxes);
    }

    const onCustomExpertCancelHandler = () => {
        setOthersInputShow(false);
    }

    return (
        <div className="dropdown__wrapper">
            <input type='text' name={fieldId} disabled={isDisabled}
                className={`dropdown--textbox input-fields ${fieldId} ${isInvalidField && 'form-control is-invalid'}`} id='custom-dropdown' value={isMultiSelect ? '' : selectedValue}
                htmlFor="providersData" readOnly placeholder={placeHolder}
                onClick={(event) => {
                    event.stopPropagation();
                    setIsPopupOpen(!isPopupOpen);
                }}
            ></input>
            {isMultiSelect && <div className="selected--checkboxes">{renderSelectedCheckBoxes()}</div>}
            <img className={`arrow-img ${fieldId}`} src={DownArrow} alt="DownArrow"
                onClick={(event) => {
                    event.stopPropagation();
                    if(!isDisabled){
                        setIsPopupOpen(!isPopupOpen);
                    }
                }} />
            {
                isPopupOpen &&
                <div className="dropdown__popup" ref={popupRef}>
                    <ul>
                        {isMultiSelect ?
                            data?.map((option, popupIndex) => (
                                <li key={popupIndex}>
                                    <Checkbox className='een-checkbox' label={option.name} onChange={handleCheckBoxChange} checked={selectedCheckBoxes.includes(option.name)} disabled={disabledValues.findIndex((item) => item.name == option.name) > -1} />
                                </li>
                            ))
                            : data?.map((option, popupIndex) => (
                                <li key={popupIndex} onClick={(event) => handleSelectProvider(event)}>{option.name}</li>
                            ))
                        }
                        {isAddNewAvailable && <li className='custom-field-li'>
                            {!showOthersInput && <div className='custom-field' onClick={() => onOthersClickHandler()}><img src={AddMoreIcon}/> Add Custom Expert Type/Module</div>}
                            {showOthersInput && <div className='custom-input' ref={othersFieldRef}>
                                <label htmlFor='customValue'>Custom Expert Type/Module Title</label>
                                <input className='input-fields' type='text' id='customValue' placeholder='Custom Expert Type/Module Title' />
                                <button className='other-button add-button' onClick={() => onCustomExpertSaveHandler()}><CheckMarkImg /></button>
                                <button className='other-button cancel-button' onClick={() => onCustomExpertCancelHandler()}><CancelImg /></button>
                            </div>
                            }
                        </li>}
                    </ul>
                </div>
            }
        </div>
    )
}