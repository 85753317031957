import React, { useEffect, useRef, useState } from "react";
import DatePicker from "react-date-picker";
import CalIcon from '../../assets/images/calendar_icon.svg';
import "react-date-picker/dist/DatePicker.css";
import "./DatePickerField.scss";

export const DatePickerField = ({ savedValue, onDateSelectHandler, minDateValue, isInvalidField, isDisabled, onCalClose, fieldId, formView }) => {
  const [selectedDate, setSelectedDate] = useState();
  const [minDate, setMinDate] = useState(new Date());

  useEffect(() => {
    if (savedValue) {
      setSelectedDate(new Date(savedValue));
    }
    if (minDateValue) {
      if (formView == 'edit') {
        if (fieldId.includes('End') &&
          (new Date(savedValue) > new Date() || new Date(savedValue).toDateString() == new Date().toDateString())) {
          setMinDate(new Date());
        } else if (fieldId.includes('Start') && savedValue) {
          setMinDate(new Date(savedValue));
        }
        else {
          setMinDate(new Date(minDateValue));
        }
      }
      else {
        if (savedValue && new Date(savedValue) < new Date(minDateValue)) {
          dateChangeHandler(minDateValue);
        }
        setMinDate(new Date(minDateValue));
      }
    }
  }, [minDateValue, savedValue]);

  const dateChangeHandler = (value) => {
    if (value?.getDate() != selectedDate?.getDate()) {
      setSelectedDate(new Date(value));
      onDateSelectHandler(value);
      return true;
    }
  }

  const onCalendarOpenHandler = (event) => {
    if (selectedDate < new Date()) {
      setMinDate(new Date());
      setSelectedDate(new Date());
      onDateSelectHandler(new Date());
    }
    onCalClose(event);
  }


  return (
    <div className={`app-date-picker-wrapper ${isInvalidField && 'invalid-img form-control is-invalid'}`}>
      <DatePicker
        onChange={(value) => dateChangeHandler(value)}
        value={selectedDate}
        name={fieldId}
        format="dd/MM/y"
        dayPlaceholder="DD"
        monthPlaceholder="MM"
        yearPlaceholder="YYYY"
        clearIcon=""
        disabled={isDisabled}
        minDate={minDate}
        className="app-date-picker"
        calendarIcon={<img src={CalIcon} alt="calender" />}
        onCalendarOpen={(event) => onCalendarOpenHandler(event)}
      // Other props can be added here
      />
    </div>
  );
};
