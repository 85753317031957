import { PEOPLE_DATA } from 'redux/constants';

//  People CCO actions
export const getPeopleAPIData = (hrid) => ({
  type: PEOPLE_DATA.API_PEOPLE_DATA,
  payload: hrid
});

export const getPeopleAPIDataPending = () => ({
  type: PEOPLE_DATA.API_PEOPLE_RESULTS_PENDING,
});

export const getPeopleAPIDataSuccess = (payload) => ({
  type: PEOPLE_DATA.API_PEOPLE_RESULTS_SUCCESS,
  payload
});

export const getPeopleAPIDataFailure = (error) => ({
  type: PEOPLE_DATA.API_PEOPLE_RESULTS_FAILURE,
  payload: { error: error?.toString() }
});

export const clearPeopleResults = () => ({
  type: PEOPLE_DATA.CLEAR_PEOPLE_RESULTS,
});