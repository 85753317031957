import { CHARGE_CODE_DETAILS } from 'redux/constants';

//  People CCO actions
export const getChargeCodeDetailsAPIData = (chargeCode) => ({
    type: CHARGE_CODE_DETAILS.API_CHARGE_CODE_DETAILS_DATA,
    payload: chargeCode
});

export const getChargeCodeDetailsAPIDataPending = () => ({
    type: CHARGE_CODE_DETAILS.API_CHARGE_CODE_DETAILS_RESULTS_PENDING
});

export const getChargeCodeDetailsAPIDataSuccess = (payload) => ({
    type: CHARGE_CODE_DETAILS.API_CHARGE_CODE_DETAILS_RESULTS_SUCCESS,
    payload
});

export const getChargeCodeDetailsAPIDataFailure = (error) => ({
    type: CHARGE_CODE_DETAILS.API_CHARGE_CODE_DETAILS_RESULTS_FAILURE,
    payload: { error: error?.toString() }
});
