import { SAVE_CONSENT } from 'redux/constants';

//  People CCO actions
export const getSaveConsentAPIData = (requestData) => ({
    type: SAVE_CONSENT.API_SAVE_CONSENT,
    payload: requestData
});

export const getSaveConsentAPIDataPending = () => ({
    type: SAVE_CONSENT.API_SAVE_CONSENT_PENDING
});

export const getSaveConsentAPIDataSuccess = (payload) => ({
    type: SAVE_CONSENT.API_SAVE_CONSENT_SUCCESS,
    payload
});

export const getSaveConsentAPIDataFailure = (error) => ({
    type: SAVE_CONSENT.API_SAVE_CONSENT_FAILURE,
    payload: { error: error?.toString() }
});
