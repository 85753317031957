import { EXP_REC_FILTERS } from "redux/constants";
export const name = "expRecFilters";

// initial state
export const initialState = {
    loading: true,
    error: false,
    errorMessage: "",
    expRecFilters: {},
    noRecordFound: false,
};

// reducer selectors
export const selectors = {
    getLoading: state => state[name].loading,
    getError: state => state[name].error,
    getErrorMessage: state => state[name].errorMessage,
    getExpRecFiltersResults: (state) => state[name]?.expRecFilters ? state[name]?.expRecFilters : "",
    getNoRecordFound: state => state[name].noRecordFound,
};

// reducer
export function reducer(state = initialState, action) {
    switch (action.type) {
        case EXP_REC_FILTERS.API_EXP_REC_FILTERS_RESULTS_FAILURE:
            return {
                ...state,
                loading: false,
                error: true,
                errorMessage: action.payload?.data,
            };
        case EXP_REC_FILTERS.API_EXP_REC_FILTERS_RESULTS_PENDING:
            return {
                ...state,
                loading: true,
                noRecordFound: false,
                error: false,
                errorMessage: '',
            };
        case EXP_REC_FILTERS.API_EXP_REC_FILTERS_RESULTS_SUCCESS:
            return {
                ...state,
                loading: false,
                noRecordFound: action.payload?.length <= 0,
                expRecFilters: action?.payload,
            };
        default:
            return state;
    }
}