// Constant
import { ENTITLEMENTS, AUTH } from "redux/constants";
import CONSTANTS from "globalConstants";

const {
    API_STATUS: {
        PENDING, COMPLETED, ERROR
    }
} = CONSTANTS;

export const checkSiteAdminPending = () => ({
    type: ENTITLEMENTS.API_CHECK_SITE_ADMIN_PENDING
});

export const checkSiteAdminSuccess = (res) => ({
    type: ENTITLEMENTS.API_CHECK_SITE_ADMIN_SUCCESS,
    payload: {
        res
    }
});

export const checkSiteAdminFailure = (error) => ({
    type: ENTITLEMENTS.API_CHECK_SITE_ADMIN_FAILURE,
    payload: {
        error: error.toString()
    }
});

export const getSmartLogicBearerTokenData = (response) => ({
    type: AUTH.GET_SMARTLOGIC_BEARER_TOKEN,
    payload: {
        response
    }
});
export const getSmartLogicBearerTokenPending = () => ({
    type: AUTH.GET_SMARTLOGIC_BEARER_TOKEN_PENDING
});
export const getSmartLogicBearerTokenSuccess = (response) => ({
    type: AUTH.GET_SMARTLOGIC_BEARER_TOKEN_SUCCESS,
    payload: {
        response
    }
});
export const getSmartLogicBearerTokenFailure = (error) => ({
    type: AUTH.GET_SMARTLOGIC_BEARER_TOKEN_FAILURE,
    payload: {
        error: error.toString()
    }
});
