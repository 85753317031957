import { put, call, takeLatest } from 'redux-saga/effects';
// Config
import CONFIG from "config";
import { SAVE_CALL_SLOTS } from "redux/constants";
// Axios Util
import { doAxiosRequest } from "config-axios";
// Actions
import {
    getSaveCallSlotsAPIDataPending,
    getSaveCallSlotsAPIDataFailure,
    getSaveCallSlotsAPIDataSuccess
} from "./saveCallSlots.actions";

import { setError } from '../error/error.actions';

export function* saveCallSlotsData(requestData) {
    const data = requestData.payload ? requestData.payload.data : null;
    if (data) {
        try {
            let requestURL = CONFIG.API_URL.POST_CALL_SLOTS;
            const axiosConfig = {
                method: 'POST',
                endpoint: requestURL,
                headers: {
                    'x-api-key': CONFIG.BE_X_API_KEY,
                    'content-type': CONFIG.HTTP_CONTENT_TYPES.json,
                },
                params: data
            };
            yield put(getSaveCallSlotsAPIDataPending());
            const response = yield call(doAxiosRequest, axiosConfig);
            if (response) {
                yield put(getSaveCallSlotsAPIDataSuccess(response));
            }
            // else {
            //     yield put(getSaveCallSlotsAPIDataFailure(CONFIG.DISPLAY_TEXT.ERROR_MESSAGES.NOT_FOUND));
            // }
        }
        catch (err) {
            yield (put(setError(err)));
            yield put(getSaveCallSlotsAPIDataFailure(CONFIG.DISPLAY_TEXT.ERROR_MESSAGES.NOT_FOUND));
        }
    }
}

export default function* saveCallSlotsAPISaga() {
    try {
        yield takeLatest(SAVE_CALL_SLOTS.API_SAVE_CALL_SLOTS, saveCallSlotsData);

    } catch (error) {
        yield (put(setError(error)));
    }
}