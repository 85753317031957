import { DISPUTE_CALL } from 'redux/constants';

//  People CCO actions
export const disputeCallAPIData = (data) => ({
    type: DISPUTE_CALL.API_DISPUTE_CALL,
    payload: data
});

export const disputeCallAPIDataPending = () => ({
    type: DISPUTE_CALL.API_DISPUTE_CALL_RESULTS_PENDING
});

export const disputeCallAPIDataSuccess = (payload) => ({
    type: DISPUTE_CALL.API_DISPUTE_CALL_RESULTS_SUCCESS,
    payload
});

export const disputeCallAPIDataFailure = (error) => ({
    type: DISPUTE_CALL.API_DISPUTE_CALL_RESULTS_FAILURE,
    payload: { error: error?.toString() }
});

export const clearDisputeCallAPIData = () => ({
    type: DISPUTE_CALL.API_CLEAR_DISPUTE_CALL,
  });