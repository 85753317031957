import { COMM_TEMPLATES } from 'redux/constants';

//  Communication Template actions
export const getCommTemplatesAPIData = (data, page, sortBy) => ({
    type: COMM_TEMPLATES.API_COMM_TEMPLATES,
    payload: {
        page: page,
        data: data,
        sortBy
    }
});

export const getCommTemplatesAPIDataPending = (page) => ({
    type: COMM_TEMPLATES.API_COMM_TEMPLATES_PENDING,
    payload: page
});

export const getCommTemplatesAPIDataSuccess = (payload) => ({
    type: COMM_TEMPLATES.API_COMM_TEMPLATES_SUCCESS,
    payload
});

export const getCommTemplatesAPIDataFailure = (error) => ({
    type: COMM_TEMPLATES.API_COMM_TEMPLATES_FAILURE,
    payload: { error: error?.toString() }
});
