import { put, call, takeLatest } from 'redux-saga/effects';
// Config
import CONFIG from "config";
import { EDIT_TEMPLATE } from "redux/constants";
// Axios Util
import { doAxiosRequest } from "config-axios";
// Actions
import {
    getEditTemplatesAPIDataPending,
    getEditTemplatesAPIDataFailure,
    getEditTemplatesAPIDataSuccess
} from "./editTemplate.actions";

import { setError } from '../error/error.actions';


export function* getEditTemplatesAPIData(action) {
    try {
        const axiosConfig = {
            method: 'put',
            endpoint: CONFIG.API_URL.EDIT_TEMPLATE,
            headers: {
                'x-api-key': CONFIG.BE_X_API_KEY,
                'content-type': CONFIG.HTTP_CONTENT_TYPES.json
            },
            data: JSON.stringify(action?.payload)
        };
        yield put(getEditTemplatesAPIDataPending());
        const response = yield call(doAxiosRequest, axiosConfig);
        if (response) {
            yield put(getEditTemplatesAPIDataSuccess(response));
        }
        else {
            yield put(getEditTemplatesAPIDataFailure(CONFIG.DISPLAY_TEXT.ERROR_MESSAGES.NOT_FOUND));
        }
    }
    catch (err) {
        yield (put(setError(err)));
        yield put(getEditTemplatesAPIDataFailure(CONFIG.DISPLAY_TEXT.ERROR_MESSAGES.NOT_FOUND));
    }
}

export default function* editTemplatesAPISaga() {
    try {
        yield takeLatest(EDIT_TEMPLATE.API_EDIT_TEMPLATE_DATA, getEditTemplatesAPIData);

    } catch (error) {
        yield (put(setError(error)));
    }
}