import { GLOBAL_ERROR } from "redux/constants";
export const name = 'globalError';

export const initialState = {
    error: false,
}

export const selectors = {
    getError: (state) => state[name].error,
}

export function reducer(state = initialState, action) {
    switch (action.type) {
        case GLOBAL_ERROR.ERROR_HANDLING:
            return {
                ...state,
                error: true
            }
        default:
            return {
                ...state
            }
    }
}