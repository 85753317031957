import { put, call, takeLatest, takeEvery } from 'redux-saga/effects';
import CONFIG from "config";
import { REQUEST_DETAILS } from "redux/constants";
import { doAxiosRequest } from "config-axios";
import {
    getRequestDetailsAPIDataPending,
    getRequestDetailsAPIDataFailure,
    getRequestDetailsAPIDataSuccess
} from "./requestDetails.actions";

import { setError } from '../error/error.actions';


export function* getrequestDetailsAPIData(action) {
    try {
        const { API_URL: { GET_REQUEST_DETAILS, UPDATE_REQUEST_DETAILS } } = CONFIG;
        let paramURL = GET_REQUEST_DETAILS(action?.payload?.interviewRequestId);
            if (action?.payload?.type == 'update') {
                paramURL = UPDATE_REQUEST_DETAILS(action?.payload?.interviewRequestId);
            }
            const axiosConfig = {
                method: 'GET',
                endpoint: paramURL,
                headers: {
                    'x-api-key': CONFIG.BE_X_API_KEY,
                    'content-type': CONFIG.HTTP_CONTENT_TYPES.json,
                },
            };
            yield put(getRequestDetailsAPIDataPending());
            const response = yield call(doAxiosRequest, axiosConfig);
            if (response) {
                if (response.data) {
                    response.data.reqType = action?.payload?.type;
                } else {
                    response.reqType = action?.payload?.type;
                }
                yield put(getRequestDetailsAPIDataSuccess(response));
            }
    }
    catch (err) {
        yield (put(setError(err)));
        yield put(getRequestDetailsAPIDataFailure(CONFIG.DISPLAY_TEXT.ERROR_MESSAGES.NOT_FOUND));
    }
}

export default function* requestDetailsAPISaga() {
    try {
        yield takeEvery(REQUEST_DETAILS.API_REQUEST_DETAILS_DATA, getrequestDetailsAPIData);

    } catch (error) {
        yield (put(setError(error)));
    }
}