import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Image from "components/shared/Image";
import TranscriptLogo from 'assets/images/TranscriptLibrary.svg';
import TranscriptLogoButton from 'assets/images/opentranscript.svg';
import WatchVideoIcon from 'assets/images/watchnewvideo.svg';
import NewFeatureIcon from 'assets/images/newfeature.svg';
import { getUserId } from "utils/auth/auth";
import { getUserProfilePictures } from "api/getUserProfilePictures";
import { getStaffId, initiateTabClickAnalytics } from '../../analytics.js';
import './NewUser.scss';

export const NewUser = () => {
    useEffect(() => {
        getUserData();
    }, []);

    const getUserData = async () => {
        const hrid = await getUserId();
        const resp = await getUserProfilePictures([hrid]);
        if (resp?.length > 0) {
            getStaffId(resp[0]?.email);
        }
    }

    return (
        <div className='newUser__wrap'>

            <div className='newUser__right'>
                <div className='newfeature--text'>Request and Manage Expert Interviews</div>
                <div className='newfeature--subtext'>The External Expert Hub offers a centralized solution to manage all experts provided by different vendors in a consistent format. You can easily prioritize preferred experts and decline those who don&#39;t meet your needs, schedule calls directly through the platform, request additional information about experts, and monitor your case budget. For a comprehensive guide on using the platform, please watch our &quot;how-to&quot; videos.</div>
                <Image src={NewFeatureIcon} alt="NewFeatureIcon" className='newfeature--img' />
                <div className='newfeature__button'
                // onClick={() => window.open('https://transcriptlibrary.bcg.com/')}
                >
                    Watch How to Videos
                    <Image src={WatchVideoIcon} alt="WatchVideoIcon" className='logo--btn' />
                </div>
            </div>

            <div className='newUser__left'>
                <div className='transcript__bottom'>
                    <div className='transcriptRight--text'>Explore Our Transcript Library</div>
                    <div className='transcriptRight--subtext'>Discover a wealth of knowledge in our Transcript Library, where you can access detailed transcripts of past interviews. Utilizing this resource not only enhances your research but also drives cost efficiency for your projects. Dive into our archives to accelerate your insights today!</div>
                    <Image src={TranscriptLogo} alt="TranscriptLogo" className='transcript--img' />
                    <div className='transcript__button' onClick={() => {
                        initiateTabClickAnalytics('EEH Home Page', 'Open Transcript Library', 'EEH Home Page');
                        window.open('https://transcriptlibrary.bcg.com/');
                    }}>
                        Open Transcript Library
                        <Image src={TranscriptLogoButton} alt="TranscriptLogoButton" className='logo--btn' />
                    </div>

                </div>
            </div>
        </div>
    )
}
