import { DELETE_FILE } from "redux/constants";
export const name = "deleteAttachment";

// initial state
export const initialState = {
    loading: true,
    error: false,
    errorMessage: "",
    deleteFileResults: [],
    noRecordFound: false,
};

// reducer selectors
export const selectors = {
    getLoading: state => state[name].loading,
    getError: state => state[name].error,
    getErrorMessage: state => state[name].errorMessage,
    getDeleteFileResults: (state) => state[name]?.deleteFileResults ? state[name]?.deleteFileResults : "",
    getNoRecordFound: state => state[name].noRecordFound,
};

// reducer
export function reducer(state = initialState, action) {
    switch (action.type) {
        case DELETE_FILE.API_DELETE_FILE_FAILURE:
            return {
                ...state,
                loading: false,
                error: true,
                errorMessage: action.payload
            };
        case DELETE_FILE.API_DELETE_FILE_PENDING:
            return {
                ...state,
                loading: true,
                noRecordFound: false,
                error: false,
                errorMessage: ''
            };
        case DELETE_FILE.API_DELETE_FILE_SUCCESS:
            return {
                ...state,
                loading: false,
                noRecordFound: action.payload?.length <= 0,
                deleteFileResults: action.payload
            };
        default:
            return state;
    }
}