import React, { useEffect, useState } from "react";
import Modal from "components/shared/Modal";
import './ApproveTemplate.scss';

export const ApproveAllTemplates = ({ showApproveAllPopup, handleClosePopup, handleApproveTemplate, showApproveClicked }) => {
    const [openModel, setOpenModel] = useState(showApproveAllPopup);
    return (
        <Modal
            isOpen={openModel}
            className="approveTemplates--modal">

            <div className="approveTemplates__header">
                Confirm Bulk Approval
            </div>

            <div className="approveTemplates__body">
                <div className="approveTemplates--bodyText">
                    Are you sure you want to approve all selected templates? This action will finalize the approval process for these documents and cannot be undone. Please confirm to proceed.
                </div>
            </div>

            <div className="approveTemplates__footer">
                <button className="cancel--btn" onClick={() => handleClosePopup()}>No, Cancel</button>
                <button className={showApproveClicked ? "approve--btn disabled--btn" : "approve--btn"} onClick={() => !showApproveClicked && handleApproveTemplate('approveAll')}>Yes, Approve All</button>
            </div>
        </Modal>
    )
}