import { SHARE_VIA_EMAIL } from "redux/constants";
export const name = "shareViaEmail";

// initial state
export const initialState = {
    loading: false,
    error: false,
    errorMessage: "",
    shareEmailApprovalData: [],
};

// reducer selectors
export const selectors = {
    getLoading: state => state[name].loading,
    getError: state => state[name].error,
    getErrorMessage: state => state[name].errorMessage,
    getshareEmailApprovalData: (state) => state[name]?.shareEmailApprovalData ? state[name]?.shareEmailApprovalData : "",
};

// reducer
export function reducer(state = initialState, action) {
    switch (action.type) {
        case SHARE_VIA_EMAIL.API_SHARE_VIA_EMAIL_RESULTS_FAILURE:
            return {
                ...state,
                loading: false,
                error: true,
                errorMessage: action.payload,
            };
        case SHARE_VIA_EMAIL.API_SHARE_VIA_EMAIL_RESULTS_PENDING:
            return {
                ...state,
                loading: true,
                error: false,
                errorMessage: '',
            };
        case SHARE_VIA_EMAIL.API_SHARE_VIA_EMAIL_RESULTS_SUCCESS:
            return {
                ...state,
                loading: false,
                shareEmailApprovalData: action?.payload,
                errorMessage: action?.payload?.errors && action?.payload?.errors[0],
            };
        case SHARE_VIA_EMAIL.CLEAR_SHARE_VIA_EMAIL:
            return {
                ...state,
                shareEmailApprovalData: [],
            }

        case SHARE_VIA_EMAIL.API_SHARE_VIA_EMAIL_RESULTS_RESET:
            return {
                ...state,
                loading: false,
                error: true,
                errorMessage: action.payload,
                shareEmailApprovalData: [],
            }
        default:
            return state;
    }
}