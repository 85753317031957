import React from "react";
import { Table } from "semantic-ui-react";
import "./DataDisplayGrid.scss";

export const DataDisplayGrid = ({ columns, data, showTenure }) => {
    return (
        <Table sortable celled className='customgrid__main'>
            <Table.Header className='grid-header'>
                <Table.Row>
                    {columns.length > 0 && columns.map((column, index) => (
                        <Table.HeaderCell key={index} className={`header-title ${index != columns.length - 1 && 'grid-header-border'}`}>{column.name}</Table.HeaderCell>
                    ))}
                </Table.Row>
            </Table.Header>
            <Table.Body className='grid-body'>
                {data?.length > 0 && data.map((record, index) => (
                    <Table.Row key={index}>
                        {columns.length > 0 && columns.map((column, colIndex) => (
                            <Table.Cell
                                key={colIndex}
                                className={`${colIndex === 0 ? 'grid-column-left-border' : 'grid-column-no-left-border'}`}
                            >
                                {column.value === "tenure" ? (
                                    <div className="no-margin m-0" dangerouslySetInnerHTML={{ __html: record[column.value] }} />
                                ) : (
                                    record[column.value]
                                )}
                            </Table.Cell>
                        ))}
                    </Table.Row>
                ))}
            </Table.Body>

        </Table>
    );
};