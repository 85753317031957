import { SAVE_CALL_SLOTS } from "redux/constants";
export const name = "saveCallsSlots";

// initial state
export const initialState = {
    loading: true,
    error: false,
    errorMessage: "",
    saveRequestResults: [],
    noRecordFound: false,
};

// reducer selectors
export const selectors = {
    getLoading: state => state[name].loading,
    getError: state => state[name].error,
    getErrorMessage: state => state[name].errorMessage,
    getSaveRequestResults: (state) => state[name]?.saveRequestResults ? state[name]?.saveRequestResults : "",
    getNoRecordFound: state => state[name].noRecordFound,
};

// reducer
export function reducer(state = initialState, action) {
    switch (action.type) {
        case SAVE_CALL_SLOTS.API_SAVE_CALL_SLOTS_FAILURE:
            return {
                ...state,
                loading: false,
                error: true,
                errorMessage: action.payload
            };
        case SAVE_CALL_SLOTS.API_SAVE_CALL_SLOTS_PENDING:
            return {
                ...state,
                loading: true,
                noRecordFound: false,
                error: false,
                errorMessage: ''
            };
        case SAVE_CALL_SLOTS.API_SAVE_CALL_SLOTS_SUCCESS:
            return {
                ...state,
                loading: false,
                noRecordFound: action.payload?.length <= 0,
                saveRequestResults: action.payload
            };
        default:
            return state;
    }
}