import { put, call, takeLatest } from 'redux-saga/effects';
// Config
import CONFIG from "config";
import { PROFILE_ACTION } from "redux/constants";
// Axios Util
import { doAxiosRequest } from "config-axios";
// Actions
import {
    getExpertProfileActionsAPIDataPending,
    getExpertProfileActionsAPIDataFailure,
    getExpertProfileActionsAPIDataSuccess
} from "./expertProfileActions.actions";

import { setError } from '../error/error.actions';

export function* saveExpertProfileAction(requestData) {
    const data = requestData.payload ? requestData.payload : null;
    if (data) {
        try {
            const axiosConfig = {
                method: 'PATCH',
                endpoint: CONFIG.API_URL.SET_PROFILE_ACTION,
                headers: {
                    'x-api-key': CONFIG.BE_X_API_KEY,
                    'content-type': CONFIG.HTTP_CONTENT_TYPES.json,
                },
                params: data
            };
            yield put(getExpertProfileActionsAPIDataPending());
            const response = yield call(doAxiosRequest, axiosConfig);
            if (response) {
                yield put(getExpertProfileActionsAPIDataSuccess(response));
            }
            // else {
            //     yield put(getExpertProfileActionsAPIDataFailure(CONFIG.DISPLAY_TEXT.ERROR_MESSAGES.NOT_FOUND));
            // }
        }
        catch (err) {
            yield (put(setError(err)));
            yield put(getExpertProfileActionsAPIDataFailure(CONFIG.DISPLAY_TEXT.ERROR_MESSAGES.NOT_FOUND));
        }
    }
}

export default function* saveProfileActionRequestAPISaga() {
    try {
        yield takeLatest(PROFILE_ACTION.API_PROFILE_ACTION, saveExpertProfileAction);

    } catch (error) {
        yield (put(setError(error)));
    }
}