import { EDIT_TEMPLATE } from 'redux/constants';

//  People CCO actions
export const getEditTemplatesAPIData = (data) => ({
    type: EDIT_TEMPLATE.API_EDIT_TEMPLATE_DATA,
    payload: data
});

export const getEditTemplatesAPIDataPending = () => ({
    type: EDIT_TEMPLATE.API_EDIT_TEMPLATE_PENDING
});

export const getEditTemplatesAPIDataSuccess = (payload) => ({
    type: EDIT_TEMPLATE.API_EDIT_TEMPLATE_SUCCESS,
    payload
});

export const getEditTemplatesAPIDataFailure = (error) => ({
    type: EDIT_TEMPLATE.API_EDIT_TEMPLATE_FAILURE,
    payload: { error: error?.toString() }
});
