import { put, call, takeLatest } from 'redux-saga/effects';
// Config
import CONFIG from "config";
import { EXPERT_TYPES } from "redux/constants";
// Axios Util
import { doAxiosRequest } from "config-axios";
import { moduleFailure } from "redux/global.action.js";
// Actions
import {
    getExpertTypeAPIDataPending,
    getExpertTypeAPIDataFailure,
    getExpertTypeAPIDataSuccess
} from "./expertTypes.actions";

import { setError } from '../error/error.actions';


export function* getExpertTypeAPIData() {
    let formatData = [];
    try {
        const axiosConfig = {
            method: 'GET',
            endpoint: CONFIG.API_URL.GET_EXPERT_TYPES,
            headers: {
                'x-api-key': CONFIG.BE_X_API_KEY,
                'content-type': CONFIG.HTTP_CONTENT_TYPES.json,
            },
        };
        yield put(getExpertTypeAPIDataPending());
        const response = yield call(doAxiosRequest, axiosConfig);
        if (response) {
            let temp = response?.expertTypes;
            temp?.map((item, index) => {
                formatData?.push({
                    name: item?.expertTypeName,
                    type: item?.type,
                    id: item?.expertTypeId
                })
            })
            yield put(getExpertTypeAPIDataSuccess(formatData));
        }
        // else {
        //     yield put(getExpertTypeAPIDataFailure(CONFIG.DISPLAY_TEXT.ERROR_MESSAGES.NOT_FOUND));
        // }
    }
    catch (err) {
        yield (put(setError(err)));
        yield put(getExpertTypeAPIDataFailure(CONFIG.DISPLAY_TEXT.ERROR_MESSAGES.NOT_FOUND));
    }
}

export default function* expertTypeAPISaga() {
    try {
        yield takeLatest(EXPERT_TYPES.API_EXPERT_TYPES_DATA, getExpertTypeAPIData);

    } catch (error) {
        yield (put(setError(error)));
    }
}