import { EXP_RECEIVED } from 'redux/constants';

//  Communication Template actions
export const getExpReceivedAPIData = (data, page, size, sortBySelValue) => ({
    type: EXP_RECEIVED.API_EXP_RECEIVED,
    payload: {
        page: page,
        size: size ? size : 10,
        data: data,
        sortBy: sortBySelValue
    }
});

export const getExpReceivedAPIDataPending = (page) => ({
    type: EXP_RECEIVED.API_EXP_RECEIVED_PENDING,
    payload: page
});

export const getExpReceivedAPIDataSuccess = (payload) => ({
    type: EXP_RECEIVED.API_EXP_RECEIVED_SUCCESS,
    payload
});

export const getExpReceivedAPIDataFailure = (error) => ({
    type: EXP_RECEIVED.API_EXP_RECEIVED_FAILURE,
    payload: { error: error?.toString() }
});
