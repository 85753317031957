import React, { useState } from "react";
import PhoneIcon from "assets/images/phone-icon.svg";
import ExpertProfile from "assets/images/expert-profile.svg";
import ExpertInterviewed from "assets/images/expert-interviewed.svg";
import LikeIcon from "assets/images/like.svg";
import Image from "components/shared/Image";
import './AnalyticsCards.scss';

export const AnalyticsCards = () => {
    const data = [
        {
            type: 'Expert Profiles Received',
            imgsrc: ExpertProfile,
            count: 25,
            expertinfo: [10, 9, 6]
        },
        {
            type: 'Interviews Scheduled',
            imgsrc: PhoneIcon,
            count: 10,
            expertinfo: [7, 7, 1]
        },
        {
            type: 'Experts Interviewed',
            imgsrc: ExpertInterviewed,
            count: 2,
            expertinfo: [7, 7, 1]
        },
        {
            type: 'Interviews Completed',
            imgsrc: LikeIcon,
            count: 5,
            expertinfo: [3, 3, 1]
        }
    ]
    const data2 = [];
    return (
        <div className="analyticscards__wrap">
            {
                data?.length > 0
                    ?
                    <div className="analyticscards__body analyticscards__body--data">
                        {
                            data?.map((option, index) =>
                                <div className="card__wrap" key={index}>
                                    <div className="card__head">
                                        <div className="expert-img">
                                            <Image src={option?.imgsrc} alt="ExpertProfile" className="expert--icon" />
                                        </div>
                                        <div className="card__head--data">
                                            <div className="head--count">
                                                {option?.count}
                                            </div>
                                            <div className="head--name">
                                                {option?.type}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card--divider"></div>
                                    <div className="card__body">
                                        <div className="body--head">
                                            Profiles Received For Expert Types/Modules
                                        </div>
                                        <div className="body__wrap">
                                            <div className="body--itemwrap">
                                                <div className="body--item">
                                                    Customers
                                                </div>
                                                <div className="body--itemcount">
                                                    {option.expertinfo[0]}
                                                </div>
                                            </div>

                                            <div className="body--itemwrap">
                                                <div className="body--item">
                                                    Suppliers
                                                </div>
                                                <div className="body--itemcount">
                                                    {option.expertinfo[1]}
                                                </div>
                                            </div>

                                            <div className="body--itemwrap">
                                                <div className="body--item">
                                                    Target
                                                </div>
                                                <div className="body--itemcount">
                                                    {option.expertinfo[2]}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                    </div>
                    :
                    <div className="analyticscards__body analyticscards__body--nodata">
                        <div className="nocalls__data">
                            <div className="nocalls--head">
                                No analytics data available.
                            </div>
                            <div className="nocalls--text">
                                As you progress with your request and gather data, your analytics dashboard will populate here, offering insights into your activities.
                            </div>
                        </div>
                    </div>
            }
        </div>
    )
}