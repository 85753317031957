import { EMP_DESIGNATION } from 'redux/constants';

//  Communication Template actions
export const getEmployeeDesignation = (hrid) => ({
    type: EMP_DESIGNATION.API_EMP_DESIGNATION,
    payload: hrid
});

export const getEmployeeDesignationDataPending = (page) => ({
    type: EMP_DESIGNATION.API_EMP_DESIGNATION_PENDING,
    payload: page
});

export const getEmployeeDesignationDataSuccess = (payload) => ({
    type: EMP_DESIGNATION.API_EMP_DESIGNATION_SUCCESS,
    payload
});

export const getEmployeeDesignationDataFailure = (error) => ({
    type: EMP_DESIGNATION.API_EMP_DESIGNATION_FAILURE,
    payload: { error: error?.toString() }
});
