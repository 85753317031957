import { RENAME_FILE } from 'redux/constants';

//  People CCO actions
export const getRenameFileAPIData = (requestData) => ({
    type: RENAME_FILE.API_RENAME_FILE,
    payload: requestData
});

export const getRenameFileAPIDataPending = () => ({
    type: RENAME_FILE.API_RENAME_FILE_PENDING
});

export const getRenameFileAPIDataSuccess = (payload) => ({
    type: RENAME_FILE.API_RENAME_FILE_SUCCESS,
    payload
});

export const getRenameFileAPIDataFailure = (error) => ({
    type: RENAME_FILE.API_RENAME_FILE_FAILURE,
    payload: { error: error?.toString() }
});
