import React, { useState } from "react";
import ShowMoreIcon from 'assets/images/show-more.svg';
import ShowLessIcon from 'assets/images/show-less.svg';
import Image from "components/shared/Image";
import { formatDate } from "utils/formatDate/formatDate";
import { ImageParaSkeleton } from 'components/shared/ImageParaSkeleton';
import './RequestDetailsDescription.scss';

export const RequestDetailsDescription = ({ reqData }) => {
    const [showExpandedDesc, setShowExpandedDesc] = useState(false);
    const [showExpOffLimit, setShowExpOffLimit] = useState(false);

    const handleDescShowMore = () => {
        setShowExpandedDesc(!showExpandedDesc);
    }

    const handleOffLimitShowMore = () => {
        setShowExpOffLimit(!showExpOffLimit);
    }

    return (
        <div className="reqDetails__description">
            <div className="desc--head">Description</div>
            <div className={showExpandedDesc ? "desc--text-fullHeight desc--text" : "desc--text desc--text-halfHeight"}>{reqData?.
                requestDescription ? reqData?.requestDescription : <ImageParaSkeleton showPara={true} paraCount={4} size="medium" height={4} /> }</div>
            {
                reqData?.requestDescription?.length > 227 &&
                <div className="show--more" onClick={() => handleDescShowMore()}>
                    <Image src={showExpandedDesc ? ShowLessIcon : ShowMoreIcon} alt="ShowMoreIcon" className="showMore--img" />
                    {showExpandedDesc ? 'Show Less' : 'Show More'}
                </div>

            }
            {
                reqData?.expertTypes?.length > 0 &&
                <div className="expertType__wrap">
                    <div className="expType--head">Expert Types/Modules</div>
                    <div className="expTypes__list">
                        {
                            reqData?.expertTypes?.map((item, index) =>

                                <div className="expType--item" key={item?.expertTypeId}>{item?.expertTypeName}</div>
                            )
                        }
                    </div>
                </div>
            }
            {
                reqData?.offLimitCompanies?.length > 0 &&
                <div className="offLimit__companies">
                    <div className="offLimit--head">Off-limits Companies</div>
                    <div className={showExpOffLimit ? "offLimit--data offLimit--data-fullHeight" : "offLimit--data offLimit--data-halfHeight"}>
                        <div className="offLimit--item">
                            {reqData?.offLimitCompanies ? reqData?.offLimitCompanies : <ImageParaSkeleton showPara={true} paraCount={1} size="medium" height={4} />}
                        </div>
                    </div>
                    {
                        reqData?.offLimitCompanies?.length > 200 &&
                        <div className="show--more" onClick={() => handleOffLimitShowMore()}>
                            <Image src={showExpOffLimit ? ShowLessIcon : ShowMoreIcon} alt="ShowMoreIcon" className="showMore--img" />
                            {showExpOffLimit ? 'Show Less' : 'Show More'}
                        </div>

                    }
                </div>

            }
            <div className="interview__window">
                <div className="interview--head">
                    Interview Window
                </div>
                <div className="interview--data">
                    <div className="interview--startDate">
                        Start Date : {reqData?.interviewAnticipatedStartDate ? formatDate(reqData?.interviewAnticipatedStartDate): <ImageParaSkeleton showPara={true} paraCount={1} size="small" height={4} />} -
                    </div>
                    <div className="interview--startDate">
                        End Date: {reqData?.interviewAnticipatedEndDate ? formatDate(reqData?.interviewAnticipatedEndDate) : <ImageParaSkeleton showPara={true} paraCount={1} size="small" height={4} />} (Anticipated # of Interviews : {reqData?.interviewAnticipatedCount})
                    </div>
                </div>
            </div>
        </div>
    )
}