import {
    UPLOAD_S3,
  } from 'redux/constants';
  
  export const uploadStart = (payload) => ({
    type: UPLOAD_S3.GET_UPLOAD_S3,
    payload
  });
  
  export const fetchPresignurlPending = (payload) => ({
    type: UPLOAD_S3.FETCH_PRESIGNURL_PENDING,
    payload
  });
  
  export const fetchPresignurlSuccess = (response) => ({
    type: UPLOAD_S3.FETCH_PRESIGNURL_SUCCESS,
    payload: response
  });
  
  export const fetchPresignurlFailure = (payload) => ({
    type: UPLOAD_S3.FETCH_PRESIGNURL_FAILURE,
    payload
  });
  export const getUploadPending = (payload) => ({
    type: UPLOAD_S3.GET_UPLOAD_S3_PENDING,
    payload
  });
  
  export const getUploadSuccess = (response) => ({
    type: UPLOAD_S3.GET_UPLOAD_S3_SUCCESS,
    payload: response
  });
  
  export const getUploadFailure = (payload) => ({
    type: UPLOAD_S3.GET_UPLOAD_S3_FAILURE,
    payload
  });