import { CALLS_FILTERS } from 'redux/constants';

//  People CCO actions
export const getCallsFiltersAPIData = (requestId) => ({
    type: CALLS_FILTERS.API_CALLS_FILTERS_DATA,
    payload: requestId
});

export const getCallsFiltersAPIDataPending = () => ({
    type: CALLS_FILTERS.API_CALLS_FILTERS_RESULTS_PENDING
});

export const getCallsFiltersAPIDataSuccess = (payload) => ({
    type: CALLS_FILTERS.API_CALLS_FILTERS_RESULTS_SUCCESS,
    payload
});

export const getCallsFiltersAPIDataFailure = (error) => ({
    type: CALLS_FILTERS.API_CALLS_FILTERS_RESULTS_FAILURE,
    payload: { error: error?.toString() }
});
