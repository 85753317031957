import { put, call, takeLatest } from 'redux-saga/effects';
import CONFIG from "config";
import { EXP_REC_FILTERS } from "redux/constants";
import { doAxiosRequest } from "config-axios";
import {
    getExpRecAPIDataPending,
    getExpRecAPIDataFailure,
    getExpRecAPIDataSuccess
} from "./expRecFilters.actions";

import { setError } from '../error/error.actions';


export function* getExpRecFiltersData(action) {
    try {
        const { API_URL: { GET_EXP_REC_FILTERS } } = CONFIG;
        const paramURL = GET_EXP_REC_FILTERS(action?.payload);
        const axiosConfig = {
            method: 'GET',
            endpoint: paramURL,
            headers: {
                'x-api-key': CONFIG.BE_X_API_KEY,
                'content-type': CONFIG.HTTP_CONTENT_TYPES.json,
            },
        };
        yield put(getExpRecAPIDataPending());
        const response = yield call(doAxiosRequest, axiosConfig);
        yield put(getExpRecAPIDataSuccess(response));
    }
    catch (err) {
        yield (put(setError(err)));
        yield put(getExpRecAPIDataFailure(CONFIG.DISPLAY_TEXT.ERROR_MESSAGES.NOT_FOUND));
    }
}

export default function* expRecFiltersAPISaga() {
    try {
        yield takeLatest(EXP_REC_FILTERS.API_EXP_REC_FILTERS_DATA, getExpRecFiltersData);

    } catch (error) {
        yield (put(setError(error)));
    }
}