import { EXP_RECEIVED } from "redux/constants";
export const name = "expReceived";

// initial state
export const initialState = {
    loading: true,
    error: false,
    errorMessage: "",
    expReceivedData: [],
    noRecordFound: false,
};

// reducer selectors
export const selectors = {
    getLoading: state => state[name].loading,
    getError: state => state[name].error,
    getErrorMessage: state => state[name].errorMessage,
    getExpReceivedData: (state) => state[name]?.expReceivedData ? state[name]?.expReceivedData : [],
    getNoRecordFound: state => state[name].noRecordFound,
};

// reducer
export function reducer(state = initialState, action) {
    switch (action.type) {
        case EXP_RECEIVED.API_EXP_RECEIVED_FAILURE:
            return {
                ...state,
                loading: false,
                error: true,
                errorMessage: action.payload?.expertProfiles,
            };
        case EXP_RECEIVED.API_EXP_RECEIVED_PENDING:
            return {
                ...state,
                loading: action?.payload <= 0 ? true : false,
                noRecordFound: false,
                error: false,
                errorMessage: '',
            };
        case EXP_RECEIVED.API_EXP_RECEIVED_SUCCESS:
            return {
                ...state,
                loading: false,
                noRecordFound: action?.payload?.expertProfiles === null ? true : false,
                expReceivedData: action?.payload || [],
            };
        default:
            return state;
    }
}