import { SAVE_CALL_SLOTS } from 'redux/constants';

//  People CCO actions
export const setSaveCallSlotsAPIData = (requestData) => ({
    type: SAVE_CALL_SLOTS.API_SAVE_CALL_SLOTS,
    payload: requestData
});

export const getSaveCallSlotsAPIDataPending = () => ({
    type: SAVE_CALL_SLOTS.API_SAVE_CALL_SLOTS_PENDING
});

export const getSaveCallSlotsAPIDataSuccess = (payload) => ({
    type: SAVE_CALL_SLOTS.API_SAVE_CALL_SLOTS_SUCCESS,
    payload
});

export const getSaveCallSlotsAPIDataFailure = (error) => ({
    type: SAVE_CALL_SLOTS.API_SAVE_CALL_SLOTS_FAILURE,
    payload: { error: error?.toString() }
});
