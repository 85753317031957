import { OktaAuth } from '@okta/okta-auth-js';
import CONFIG from 'config';

const {
  // ENVIRONMENT_MAIN,
  OKTA: {
    ISSUER,
    CLIENT_ID,
    REDIRECT_URL,
    TOKEN_EARLY_EXPIRE
  }
} = CONFIG;

// const baseConfig = utils.authServiceConfig(ENVIRONMENT_MAIN);
// const authConfig = {
//   issuer: ISSUER,
//   clientId: CLIENT_ID,
//   redirectUri: `${window.location.origin}${REDIRECT_URL}`,
//   pkce: true,
//   scopes: ["profile", "email", "openid", "groups"]
// }

const authConfig = {
  history,
  issuer: ISSUER,
  clientId: CLIENT_ID,
  pkce: false,
  redirectUri: `${window.location.origin}${REDIRECT_URL}`,
  tokenManager: {
    autoRenew: true,
    expireEarlySeconds: TOKEN_EARLY_EXPIRE * 60 // Emit expired event 5 minutes before expiration . Tokens accessed with tokenManager.get() will auto-renew within 5 minutes of expiration
  }
};

  export default new OktaAuth({ ...authConfig });
