import React, { useState, useEffect, useRef, createRef, useContext } from 'react';
import DownArrow from "assets/images/down_arrow.svg";
import { Image, Button } from 'semantic-ui-react';
import AddMoreIcon from "assets/images/add_more.svg";
import RemoveIcon from "assets/images/remove_icon.svg";
import CloseIcon from 'assets/images/close-chargeCode.svg';
import '@bcg-web/bcg-people-search';
import './ExpertContact.scss';
import { FormContext } from 'context/form-context';

export const ExpertContact = ({ selectedProviders, prevSelection, isDisabled, handleSelManagers, accessToken, apiKey, appName, placeholderTxt }) => {
    const popupRef = useRef(null);

    const formContext = useContext(FormContext);

    const [selectedProvidersData, setSelectedProvidersData] = useState([]);
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [resManagerObjAdded, setResManagerObjAdded] = useState(false);
    const [resManagerObj, setResManagerObj] = useState([{
        expert: '',
        member: '',
        email: ''
    }]);
    const [activeIndex, setActiveIndex] = useState(0);
    const [popupDropDownOptions, setPopupDropDownOptions] = useState([]);
    let peopleSearchRef = useRef(resManagerObj.map(() => createRef()));

    useEffect(() => {
        let timeout;
        function updateSelectionValue(e) {
            if (e.target?.id) {
                const fieldIndex = e.target.id.split('peopleSearch')[1] ? Number(e.target.id.split('peopleSearch')[1]) : -1;
                if (e.detail && e.detail.length > 0) {
                    setResManagerObj((values) => {
                        const updatedItems = [...values];
                        if (updatedItems[fieldIndex]) {
                            updatedItems[fieldIndex]['member'] = e.detail;
                            updatedItems[fieldIndex]['duplicateMembers'] = [];
                            if (updatedItems[fieldIndex]['savedExpert']?.length > 0) {
                                e.detail.forEach((people) => {
                                    const isDuplicate = updatedItems[fieldIndex]['savedExpert'].findIndex((e) => e.id == people.id);
                                    if (isDuplicate > -1) {
                                        updatedItems[fieldIndex]['duplicateMembers'].push(people.id);
                                    }
                                });

                            }
                        }
                        return updatedItems;
                    });
                } else {
                    if (e.detail?.length == 0) {
                        setResManagerObj((values) => {
                            const updatedItems = [...values];
                            if (updatedItems[fieldIndex]) {
                                updatedItems[fieldIndex]['member'] = [];
                                updatedItems[fieldIndex]['duplicateMembers'] = [];
                            }
                            return updatedItems;
                        });
                    }
                }
            }
        }
        if (!isDisabled) {
            peopleSearchRef.current.map(
                ref => {
                    if (ref?.current) {
                        const shadowRoot = ref.current.shadowRoot;
                        timeout = setTimeout(() => {
                            const element = shadowRoot.querySelector('.selection-list');
                            if (element) {
                                element.style.width = '100%';
                                element.style.height = '100%';
                                element.style.overflow = 'auto';
                                element.style.padding = 'var(--Spacing-m, 12px)';
                                element.style.gap = 'var(--Spacing-8, 8px)';
                            }
                        }, 800);
                        ref.current.addEventListener("selectedPeopleChanged", updateSelectionValue, false);
                    }
                }
            );
            return () => clearTimeout(timeout);
        }
    }, [peopleSearchRef, resManagerObjAdded, isDisabled]);

    useEffect(() => {
        if (selectedProviders) {
            setSelectedProvidersData(selectedProviders);
            setPopupDropDownOptions(selectedProviders);

            let expertObjData = resManagerObj;

            let resManagerObjTemp = expertObjData.filter(obj => selectedProviders.includes(obj.expert));
            if (resManagerObjTemp?.length == 0) {
                setResManagerObj([{ expert: '', member: '', email: '' }]);
            } else {
                if (resManagerObjTemp?.length > resManagerObj?.length) {
                    setResManagerObj([...resManagerObjTemp, { expert: '', member: '', email: '' }]);
                } else {
                    setResManagerObj(resManagerObjTemp);
                }

            }

            let resManagers = resManagerObjTemp.map(obj => obj.expert);
            let filteredSelectedProviders = selectedProviders.filter(provider => !resManagers.includes(provider));
            if (resManagerObjTemp?.length > 0) {
                if (filteredSelectedProviders?.length > 0) {
                    setActiveIndex(resManagerObjTemp?.length - 1);
                    peopleSearchRef?.current[resManagerObjTemp?.length - 1] ? '' : peopleSearchRef.current[resManagerObjTemp?.length - 1] = createRef();
                    setResManagerObjAdded(!resManagerObjAdded);
                }
                else {
                    // setActiveIndex(resManagerObj?.length - 1);
                    setActiveIndex(null);
                    // setResManagerObj([...resManagerObjTemp]);
                }
            }
            else if (resManagerObj?.length == 2) {
                setActiveIndex(0);
            }
            resManagers = resManagerObj.map(obj => obj.expert);
            // Filtering selectedProviders to contain only those values not present in resManagers
            filteredSelectedProviders = selectedProviders.filter(provider => !resManagers.includes(provider));

            setPopupDropDownOptions(filteredSelectedProviders);
            if (resManagerObjTemp?.length === 0) {
                // handleSelManagers('');
            }

            if (resManagerObjTemp?.length == 0) {
                if (peopleSearchRef?.current[0]?.current?.selectedPeople?.length > 0) {
                    peopleSearchRef.current[0].current.selectedPeople = [];
                }
            } else {
                resManagerObjTemp.forEach((obj, index) => {
                    if (!obj.member) {
                        if (peopleSearchRef?.current[index]?.current?.selectedPeople?.length > 0) {
                            peopleSearchRef.current[index].current.selectedPeople = [];
                        }
                    } else {
                        peopleSearchRef.current[index].current.selectedPeople = [];
                        obj.member.forEach((person) => {
                            peopleSearchRef.current[index].current.addToSelectedList(person);
                        });
                    }
                });
            }
        }
    }, [selectedProviders]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (popupRef.current && !popupRef.current.contains(event.target)) {
                setIsPopupOpen(false);
            }
        };

        const handleMouseDown = (event) => {
            // Check if the down arrow image was clicked
            if (!event.target.classList.contains('arrow--img') && !event.target.classList.contains('dropdown--textbox')) {
                handleClickOutside(event);
            }
        };
        document.addEventListener("mousedown", handleMouseDown);

        return () => {
            document.removeEventListener("mousedown", handleMouseDown);
        };
    }, []);

    useEffect(() => {
        if (resManagerObj.length > 0) {
            let tempExpertData = [];
            resManagerObj.forEach((obj) => {
                if (obj.member || obj.expert) {
                    tempExpertData.push(obj);
                }
            });
            tempExpertData.length > 0 ? formContext.toggleFormValues('expertContact', tempExpertData, false) : '';
        }
    }, [resManagerObj]);

    const handleSelectProvider = (event, index, field) => {
        const { outerText } = event.target;
        const updatedResManagerObj = [...resManagerObj];
        updatedResManagerObj[index][field] = outerText;
        setResManagerObj((values) => {
            const updatedItems = [...values];
            if (updatedItems[index]) {
                updatedItems[index][field] = outerText;
                const findSavedExperts = prevSelection.find((selection) => selection.name == outerText);
                if (findSavedExperts) {
                    updatedItems[index]['savedExpert'] = findSavedExperts.member;
                } else {
                    updatedItems[index]['savedExpert'] = [];
                }
            }
            return updatedItems;
        });
        setIsPopupOpen(false);
        // Filter out the dropdown options that are already selected in other input fields
        const dropdownOptions = selectedProvidersData.filter(option =>
            !updatedResManagerObj.some(obj => obj.expert === option)
        );
        setPopupDropDownOptions(dropdownOptions);
        if (dropdownOptions?.length == 0) {
            // setActiveIndex(null);
        }
        // handleSelManagers(outerText);
    }

    const deleteRefAtIndex = (indexToDelete) => {
        const newArray = [...peopleSearchRef.current];
        newArray.splice(indexToDelete, 1);
        peopleSearchRef.current = newArray;
    };
    const handleAddMore = (index) => {
        if (resManagerObj[index].expert !== '') {
            if (index === activeIndex && popupDropDownOptions?.length !== 0) {
                // If the "Add More" button is clicked, add a new row
                setResManagerObj((values) => {
                    const updatedItems = [...values];
                    updatedItems.push({ expert: '', member: '', email: '' });
                    return updatedItems;
                });
                peopleSearchRef.current[index + 1] = createRef();
                setResManagerObjAdded(!resManagerObjAdded);
                setActiveIndex(index + 1);
            } else {
                // If the "Remove" button is clicked, remove the corresponding row
                const updatedResManagerObj = resManagerObj.filter((_, idx) => idx !== index);
                if (updatedResManagerObj.length === 1) {
                    setResManagerObj([{ expert: updatedResManagerObj[0]?.expert, member: updatedResManagerObj[0]?.member, email: updatedResManagerObj[0]?.email }]);
                    setActiveIndex(0);
                    peopleSearchRef.current[0].current.selectedPeople = [];
                    if (updatedResManagerObj[0]?.member) {
                        updatedResManagerObj[0]?.member.forEach((person) => {
                            peopleSearchRef.current[0].current.addToSelectedList(person);
                        });
                    }

                    let resManagers = updatedResManagerObj.map(obj => obj.expert);

                    // Filtering selectedProviders to contain only those values not present in resManagers
                    let filteredSelectedProviders = selectedProviders.filter(provider => !resManagers.includes(provider));
                    setPopupDropDownOptions(filteredSelectedProviders);

                    if (updatedResManagerObj?.length === 1 && updatedResManagerObj[0]?.expert === '') {
                        // handleSelManagers('');
                    }

                } else if (updatedResManagerObj.length === 0) {
                    setResManagerObj([{ expert: '', member: '', email: '' }]);
                    setActiveIndex(0);
                    let resManagers = updatedResManagerObj.map(obj => obj.expert);
                    if (peopleSearchRef?.current[0]?.current?.selectedPeople?.length > 0) {
                        peopleSearchRef.current[0].current.selectedPeople = [];
                    }
                    // handleSelManagers('');
                    // Filtering selectedProviders to contain only those values not present in resManagers
                    let filteredSelectedProviders = selectedProviders.filter(provider => !resManagers.includes(provider));
                    setPopupDropDownOptions(filteredSelectedProviders);
                    if (updatedResManagerObj?.length === 1) {
                        // handleSelManagers('');
                    }
                }
                else {
                    setResManagerObj(updatedResManagerObj);
                    setActiveIndex(updatedResManagerObj?.length - 1);

                    let resManagers = updatedResManagerObj.map(obj => obj.expert);

                    updatedResManagerObj.forEach((obj, index) => {
                        if (!obj.member) {
                            if (peopleSearchRef?.current[index]?.current?.selectedPeople?.length > 0) {
                                peopleSearchRef.current[index].current.selectedPeople = [];
                            }
                        } else {
                            peopleSearchRef.current[index].current.selectedPeople = [];
                            obj.member.forEach((person) => {
                                peopleSearchRef.current[index].current.addToSelectedList(person);
                            });
                        }
                    });
                    // Filtering selectedProviders to contain only those values not present in resManagers
                    let filteredSelectedProviders = selectedProviders.filter(provider => !resManagers.includes(provider));
                    setPopupDropDownOptions(filteredSelectedProviders);
                    if (updatedResManagerObj?.length === 1) {
                        // handleSelManagers('');
                    }
                }
                // deleteRefAtIndex(index);
            }
        }
    };

    const handleAssignMemberRemoval = (expert, hrid) => {
        setResManagerObj((values) => {
            const updatedItems = [...values];
            const recordIndex = updatedItems.findIndex((item) => item.expert == expert);
            if (recordIndex > -1) {
                if (updatedItems[recordIndex]['savedExpert']?.length > 0) {
                    updatedItems[recordIndex]['savedExpert'] = updatedItems[recordIndex]['savedExpert'].filter((e) => e.id != hrid);
                }
            }
            formContext.toggleSavedValues('caseTeamMembers', hrid, false);
            return updatedItems;
        });
    }

    return (
        <div className='expertContact__wrap'>
            <div className='expertContact__subwrap'>
                {resManagerObj.map((item, index) => (
                    <div className='expertContact__body' key={index}>
                        <div className="expertContact__input-email width70">
                            <div className="input__wrapper">
                                <input type='text'
                                    className={isDisabled ? "field-border disabled" : "dropdown--textbox field-border"}
                                    disabled={isDisabled}
                                    htmlFor="providersData" readOnly placeholder={placeholderTxt}
                                    value={item?.expert}
                                    onClick={(event) => {
                                        event.stopPropagation();
                                        if (activeIndex <= index) {
                                            setActiveIndex(index);
                                            setIsPopupOpen((prevIsPopupOpen) => !prevIsPopupOpen);
                                        }
                                    }}></input>
                                <Image className={isDisabled ? "arrow--img imgOpacity" : "arrow--img"} src={DownArrow} alt="DownArrow"
                                    onClick={(event) => {
                                        event.stopPropagation();
                                        if (activeIndex <= index) {
                                            setActiveIndex(index);
                                            setIsPopupOpen((prevIsPopupOpen) => !prevIsPopupOpen);
                                        }
                                    }} />
                                {
                                    isPopupOpen && activeIndex === index &&
                                    <div className="provider__popup" ref={popupRef}>
                                        <ul>
                                            {
                                                popupDropDownOptions?.map((data, popupIndex) => (
                                                    <li key={popupIndex} onClick={(event) => handleSelectProvider(event, index, 'expert')}>{data}</li>
                                                ))
                                            }
                                        </ul>
                                    </div>
                                }
                            </div>
                            <div className='email__wrapper'>
                                {isDisabled ?
                                    <input type='text' className={isDisabled ? "field-border disabled" : "field-border"}
                                        disabled={isDisabled} /> :
                                    <div className="people-search-wrapper">
                                        <bcg-people-search ref={peopleSearchRef.current[index]} app-name={appName} id={`peopleSearch` + index}
                                            limit="20" width="100%" is-show-profile="true" is-multi-select="true" environment='preproduction-internal'
                                            api-key={apiKey} access-token={accessToken} />
                                    </div>
                                }
                                {formContext.formView == 'edit' &&
                                    <div className="edit-caseteam">
                                        {item?.savedExpert?.map((m, index) =>
                                            <div key={index} className={`saved-case-team-member ${item.duplicateMembers?.includes(m.id) && 'duplicate-selection'}`}>
                                                <span>{m.name}</span>
                                                &ensp;<Image src={CloseIcon} onClick={() => handleAssignMemberRemoval(item?.expert, m.id)}/>
                                            </div>
                                        )
                                        }
                                    </div>
                                }
                            </div>
                        </div>

                        <Button className={isDisabled ? "addmore__btn imgOpacity" : "addmore__btn"} onClick={() => !isDisabled && handleAddMore(index)}>
                            <Image className={isDisabled ? "arrow--img" : "arrow--img"} src={index === activeIndex && selectedProviders.length - 1 != activeIndex ? AddMoreIcon : RemoveIcon} alt="AddMoreIcon" />
                            {index === activeIndex && selectedProviders.length - 1 != activeIndex ? "Add More" : "Remove"}
                        </Button>
                    </div>
                ))}
            </div>
        </div>
    )
}