import React, { useEffect, useState } from "react";
import "./PrivacyConsentModal.scss";
import Modal from "components/shared/Modal";
import Image from "components/shared/Image";
import logoIcon from "assets/images/logo.svg";
import {ReactComponent as LeftImg} from "assets/images/mask_group.svg";
import checkIcon from "assets/images/check-icon.svg";
import { useDispatch, useSelector } from "react-redux";
import { getSaveConsentAPIData } from "redux/actions/savePrivacyConsent/savePrivacyConsent.actions";
import {selectors as saveConsentSelector} from "redux/reducers/saveConsent/saveConsent_reducer";

export const PrivacyConsentModal = ({ open }) => {
    const dispatch = useDispatch();
    const saveConsentData = useSelector(saveConsentSelector.getSaveConsentResults);
    const [openModel, setOpenModel] = useState(open);

    useEffect(() => {
        if(saveConsentData?.data){
        setOpenModel(false);
        }
    },[saveConsentData]);

    const agreeButtonClick = () => {
        dispatch(getSaveConsentAPIData({privacyConsent:'true'} ));
    }

    return (
        <Modal isOpen={openModel} className="consent__modal" >
            <div className="consent__wrapper model-wrapper">
                <div className="consent__leftpanel">
                    <LeftImg />
                </div>
                <div className="consent__rightpanel">
                    <div className="consent__logo">
                        <Image src={logoIcon} alt="logo" />
                    </div>
                    <div className="consent__body">
                        <div className="heading">Welcome to the External Experts Hub!</div>
                        {/* <div className="sub-heading">{`While we partnered with Legal and Data Protection Teams, it's each user's responsibility to comply with the following rules:`}</div> */}
                        <div className="details">
                            The tool is designed to help you connect with expert networks, collect all experts in one place, easily schedule calls and keep track of your budget.
                        </div>

                        <div className="sub-heading">Please Agree Our Platform Rules</div>
                        <ul className="terms">
                            <li>
                                <img src={checkIcon} alt="checkbox" />
                                <label htmlFor="term1">I have reviewed the <a href="https://pages.navigator.bcg.com/kp/ad32eab2-c4bc-4922-9df9-c4a8c2174033" target="_blank" rel="noopener noreferrer">Compliance Guidelines</a> and am aware of the off-limits experts I must not engage with and off-limits topics I must not discuss.</label></li>
                            <li>
                                <img src={checkIcon} alt="checkbox" />
                                <label htmlFor="term2">I will not solicit any non-public information from experts.</label></li>
                            <li>
                                <img src={checkIcon} alt="checkbox" />
                                <label htmlFor="term3">I will not share any sensitive client information with Expert Networks or experts.</label></li>
                            <li>
                                <img src={checkIcon} alt="checkbox" />
                                <label htmlFor="term4">I will communicate with experts via Expert Networks unless I have paid a release fee.</label></li>
                            <li>
                                <img src={checkIcon} alt="checkbox" />
                                <label htmlFor="term5">If I need to quote an expert in a client deliverable, I will make sure to remove their PII (personal identifiable information) such as name and title/company.</label></li>
                        </ul>
                    </div>
                </div>
            </div>
            <footer className="consent__footer">
                <div className="action-container">
                    <button className="primary" onClick={() => agreeButtonClick()}>Agree & Continue</button>
                </div>
            </footer>
        </Modal>
    );
};
