import {
    UPLOAD_S3,
  } from 'redux/constants';
  import { doAxiosRequest } from 'config-axios';
//   import { isResponseOk } from "utils/helpers/helpers";
  import {
    getUploadPending,
    getUploadSuccess,
    getUploadFailure,
    fetchPresignurlPending,
    fetchPresignurlFailure,
  } from './uploadFile.action';
  
  import CONFIG from 'config';
  import { moduleFailure } from 'redux/global.action';
  import { put, takeEvery, call } from '@redux-saga/core/effects';
  
  export function* uploadStart(action) {
    const { payload: { file, filename, folderId } } = action;
    try {
      yield put(fetchPresignurlPending({
        file: file,
        uploadLoader: true
      }));
      const response = yield call(doAxiosRequest, {
        method: 'put',
        endpoint: CONFIG.API_URL.UPLOAD_FILE_URL(filename, folderId),
        file: file,
        headers: {
          'x-api-key': CONFIG.BE_X_API_KEY
        },
      });
      yield put(getUploadSuccess({
        file: filename,
        success: response,
        uploadLoader: false
      }));
      if (response) {
        // yield call(fileUploadtoS3, { payload: { folderName: response?.folderName, fileName:  response?.fileName, signedRequest: response?.signedUrl } })
      }
    } catch (error) {
      yield put(fetchPresignurlFailure({
        file: file,
        uploadLoader: false,
        error: error.toString()
      }));
  
    }
  }
  
  export default function* uploadFileSaga() {
    try {
      yield takeEvery(UPLOAD_S3.GET_UPLOAD_S3, uploadStart);
    } catch (error) {
      yield put(moduleFailure(error, uploadFileSaga))
    }
  }