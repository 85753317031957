import { CANCEL_CALL } from "redux/constants";
export const name = "cancelCall";

// initial state
export const initialState = {
    loading: true,
    error: false,
    errorMessage: "",
    cancelCallData: '',
};

// reducer selectors
export const selectors = {
    getLoading: state => state[name].loading,
    getError: state => state[name].error,
    getErrorMessage: state => state[name].errorMessage,
    getCancelCallsData: (state) => state[name]?.cancelCallData ? state[name]?.cancelCallData : "",
};

// reducer
export function reducer(state = initialState, action) {
    switch (action.type) {
        case CANCEL_CALL.API_CANCEL_CALL_RESULTS_FAILURE:
            return {
                ...state,
                loading: false,
                error: true,
                errorMessage: action.payload,
            };
        case CANCEL_CALL.API_CANCEL_CALL_RESULTS_PENDING:
            return {
                ...state,
                loading: action?.payload?.status?.length <= 0 ? true : false,
                error: false,
                errorMessage: '',
            };
        case CANCEL_CALL.API_CANCEL_CALL_RESULTS_SUCCESS:
            return {
                ...state,
                loading: false,
                cancelCallData: action?.payload?.status,
                errorMessage: action?.payload?.errors && action?.payload?.errors[0],
            };
        case CANCEL_CALL.API_CLEAR_CANCEL_CALL:
            return {
                ...state,
                cancelCallData: '',
            }
        default:
            return state;
    }
}