import React, { useState, useEffect } from 'react';
import {
    BreadcrumbSection,
    BreadcrumbDivider,
    Breadcrumb,
    Dropdown
} from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { DropdownField } from "components/shared/DropdownField";
import { ImageParaSkeleton } from 'components/shared/ImageParaSkeleton';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import './BreadCrumbs.scss';
import { BreadCrumbDropdown } from '../BreadCrumbDropdown';
import { globalAdobeAnalytics } from '../../../analytics';

const BreadCrumbs = ({ paths = [] }) => {
    const [activeItem, setActiveItem] = useState('');
    const [activeLink, setActiveLink] = useState('');
    const activepathname = window?.location?.pathname;

    useEffect(() => {
        paths.forEach((path, index) => {
            if (path?.pathname === activepathname) {
                setActiveItem(path?.name);
                setActiveLink(path?.pathname);
            }
            if (path?.children) {
                path?.children?.forEach((child) => {
                    if (child?.pathname === activepathname) {
                        setActiveItem(child?.name);
                        setActiveLink(child?.pathname);
                    }
                });
            }
        });
    }, [activepathname, paths]);
    paths.forEach((path, index) => {
        path.active = path?.pathname === activepathname;
        path.isLast = index === paths?.length - 1;
    });

    const handleDropDownItem = (data) => {
        setActiveItem(data?.name);
        setActiveLink(data?.pathname);
    }

    return (
        <Breadcrumb>
            {
                paths?.map((group, index) => (
                    <React.Fragment key={index}>
                        <BreadcrumbSection
                            as={group?.active || group?.isLast || group?.isActive ? 'span' : Link}
                            to={group?.active || group?.isLast ? null : group?.pathname}
                            active={group?.active || group?.isLast ? true : false}
                            onClick={() => {
                                if (window.location?.pathname === '/requestDetails') {
                                    globalAdobeAnalytics('EEH Home Page', 'EEH Request Details Page')
                                }
                                else {
                                    globalAdobeAnalytics('EEH Home Page', 'EEH Request Form')
                                }
                            }}
                        >
                            {group?.name}
                        </BreadcrumbSection>
                        {/* {group?.children && group?.children?.length > 0 && (
                            <BreadCrumbDropdown data={group?.children} />
                        )
                        } */}
                        {/* {paths[1]?.children?.length===0 && index === 1 && (
                            <ImageParaSkeleton showPara={true} paraCount={1} size="small" height='20' /> 
                        )} */}
                        <>
                            {index !== paths?.length - 1 && (
                                <BreadcrumbDivider
                                    className='breadcrumb--divider'
                                // active={group.active && true}
                                />
                            )}
                        </>
                    </React.Fragment>
                ))
            }
        </Breadcrumb>
    )
}

export default BreadCrumbs
