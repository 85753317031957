import React, { useState } from "react";
import Modal from "components/shared/Modal";
import Image from "components/shared/Image";
import ApproveCheck from "assets/images/approve-check.svg";
import './ApproveTemplate.scss';

export const ApproveTemplates = ({ showApprovePopup, handleClosePopup, handleApproveTemplate, showApproveClicked }) => {
    const [openModel, setOpenModel] = useState(showApprovePopup);
    return (
        <Modal
            isOpen={openModel}
            className="approveTemplates--modal">

            <div className="approveTemplates__header">
                Confirm Approval
            </div>

            <div className="approveTemplates__body">
                <div className="approveTemplates--bodyText">
                    Are you sure you want to approve this template? This action will finalize the approval process for this document and cannot be undone. Please confirm to proceed.
                </div>
                <div className="approveTemplates--bodySubText"><Image src={ApproveCheck} className='warning--img' alt="ApproveCheck" /> I Confirm I belong to one of these cohorts:</div>
                <div className="approveTemplates--designation">Principal, Partner, Partner and Associate Director, MDP or MSDP.</div>
            </div>

            <div className="approveTemplates__footer">
                <button className="cancel--btn" onClick={() => handleClosePopup()}>No, Cancel</button>
                <button className={showApproveClicked ? "approve--btn disabled--btn" : "approve--btn"} disabled={showApproveClicked} onClick={() => !showApproveClicked && handleApproveTemplate('approve')}>Yes, Approve</button>
            </div>
        </Modal>
    )
}