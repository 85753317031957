import { put, call, takeLatest } from 'redux-saga/effects';
// Config
import CONFIG from "config";
import { SAVE_CONSENT } from "redux/constants";
// Axios Util
import { doAxiosRequest } from "config-axios";
// Actions
import {
    getSaveConsentAPIDataPending,
    getSaveConsentAPIDataFailure,
    getSaveConsentAPIDataSuccess
} from "./savePrivacyConsent.actions";

import { setError } from '../error/error.actions';

export function* saveConsentRequestData(requestData) {
    const data = requestData.payload ? requestData.payload : null;
    if (data) {
        try {
            const axiosConfig = {
                method: 'POST',
                endpoint: CONFIG.API_URL.PRIVACY_CONSENT,
                headers: {
                    'x-api-key': CONFIG.BE_X_API_KEY,
                    'content-type': CONFIG.HTTP_CONTENT_TYPES.json,
                },
                params: data
            };
            yield put(getSaveConsentAPIDataPending());
            const response = yield call(doAxiosRequest, axiosConfig);
            if (response) {
                yield put(getSaveConsentAPIDataSuccess(response));
            }
            // else {
            //     yield put(getSaveConsentAPIDataFailure(CONFIG.DISPLAY_TEXT.ERROR_MESSAGES.NOT_FOUND));
            // }
        }
        catch (err) {
            yield (put(setError(err)));
            yield put(getSaveConsentAPIDataFailure(CONFIG.DISPLAY_TEXT.ERROR_MESSAGES.NOT_FOUND));
        }
    }
}

export default function* saveConsentRequestAPISaga() {
    try {
        yield takeLatest(SAVE_CONSENT.API_SAVE_CONSENT, saveConsentRequestData);

    } catch (error) {
        yield (put(setError(error)));
    }
}