import { PROFILE_ACTION } from 'redux/constants';

//  People CCO actions
export const setExpertProfileActionsAPIData = (requestData) => ({
    type: PROFILE_ACTION.API_PROFILE_ACTION,
    payload: requestData
});

export const getExpertProfileActionsAPIDataPending = () => ({
    type: PROFILE_ACTION.API_PROFILE_ACTION_PENDING
});

export const getExpertProfileActionsAPIDataSuccess = (payload) => ({
    type: PROFILE_ACTION.API_PROFILE_ACTION_SUCCESS,
    payload
});

export const getExpertProfileActionsAPIDataFailure = (error) => ({
    type: PROFILE_ACTION.API_PROFILE_ACTION_FAILURE,
    payload: { error: error?.toString() }
});
