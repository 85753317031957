import { put, call, takeLatest } from 'redux-saga/effects';
// Config
import CONFIG from "config";
import { DISPUTE_CALL } from "redux/constants";
// Axios Util
import { doAxiosRequest } from "config-axios";
import { moduleFailure } from "redux/global.action.js";
// Actions
import {
    disputeCallAPIDataPending,
    disputeCallAPIDataFailure,
    disputeCallAPIDataSuccess
} from "./disputeCall.actions";

import { setError } from '../error/error.actions';


export function* disputeCallAPIData(action) {
    try {
        const axiosConfig = {
            method: 'POST',
            endpoint: CONFIG.API_URL.DISPUTE_CALL,
            headers: {
                'x-api-key': CONFIG.BE_X_API_KEY,
                'content-type': CONFIG.HTTP_CONTENT_TYPES.json
            },
            params: action?.payload,
        };
        yield put(disputeCallAPIDataPending(action?.payload));
        const response = yield call(doAxiosRequest, axiosConfig);
        if (response) {
            yield put(disputeCallAPIDataSuccess(response));
        }
        else {
            yield put(disputeCallAPIDataFailure(CONFIG.DISPLAY_TEXT.ERROR_MESSAGES.NOT_FOUND));
        }
    }
    catch (err) {
        yield (put(setError(err)));
        yield put(disputeCallAPIDataFailure(CONFIG.DISPLAY_TEXT.ERROR_MESSAGES.NOT_FOUND));
    }
}

export default function* disputeCallAPISaga() {
    try {
        yield takeLatest(DISPUTE_CALL.API_DISPUTE_CALL, disputeCallAPIData);

    } catch (error) {
        yield (put(setError(error)));
    }
}