import React, { useState } from 'react';
import './ToggleSwitch.scss';

export const ToggleSwitch = ({ isOn, handleToggle }) => {

    const [isChecked, setIsChecked]= useState(isOn);
    const onToggleClicked=()=>{
        setIsChecked(!isChecked);
        handleToggle();
    }

    return (
        <div className='toggle-wrapper'>
        <span className='sub-field-label'>No</span>
            <input
                checked={isChecked}
                onChange={onToggleClicked}
                className="react-switch-checkbox"
                id={`react-switch-new`}
                type="checkbox"
            />
            <label
            style={{ background: isChecked && '#197A56' }}
                className="react-switch-label"
                htmlFor={`react-switch-new`}
            >
                <span className={`react-switch-button`} />
            </label>
            <span className='sub-field-label'>Yes</span>
        </div>
    );
}