import { CONSENT } from "redux/constants";
export const name = "consent";

// initial state
export const initialState = {
    loading: false,
    error: false,
    errorMessage: "",
    consentData: [],
    noRecordFound: false,
};

// reducer selectors
export const selectors = {
    getLoading: state => state[name].loading,
    getError: state => state[name].error,
    getErrorMessage: state => state[name].errorMessage,
    consentData: (state) => state[name]?.consentData ? state[name]?.consentData : "",
    getNoRecordFound: state => state[name].noRecordFound,
};

// reducer
export function reducer(state = initialState, action) {
    switch (action.type) {
        case CONSENT.API_CONSENT_RESULTS_FAILURE:
            return {
                ...state,
                loading: false,
                error: true,
                errorMessage: action?.payload?.error,
            };
        case CONSENT.API_CONSENT_RESULTS_PENDING:
            return {
                ...state,
                loading: true,
                noRecordFound: false,
                error: false,
                errorMessage: '',
            };
        case CONSENT.API_CONSENT_RESULTS_SUCCESS:
            return {
                ...state,
                loading: false,
                noRecordFound: action.payload?.privacyConsent,
                consentData: action?.payload,
            };
        default:
            return state;
    }
}