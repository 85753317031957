import { put, call, takeLatest } from 'redux-saga/effects';
import CONFIG from "config";
import { TEMPLATE_FILTERS } from "redux/constants";
import { doAxiosRequest } from "config-axios";
import {
    getTemplatesAPIDataPending,
    getTemplatesAPIDataFailure,
    getTemplatesAPIDataSuccess
} from "./templateFilters.actions";

import { setError } from '../error/error.actions';


export function* getTemplateData(action) {
    try {
        const { API_URL: { GET_TEMPLATE_FILTERS } } = CONFIG;
        const paramURL = GET_TEMPLATE_FILTERS(action?.payload);
        const axiosConfig = {
            method: 'GET',
            endpoint: paramURL,
            headers: {
                'x-api-key': CONFIG.BE_X_API_KEY,
                'content-type': CONFIG.HTTP_CONTENT_TYPES.json,
            },
        };
        yield put(getTemplatesAPIDataPending());
        const response = yield call(doAxiosRequest, axiosConfig);
        // if (response) {
            yield put(getTemplatesAPIDataSuccess(response));
        // }
        // else {
        //     yield put(getCommTemplatesAPIDataFailure(CONFIG.DISPLAY_TEXT.ERROR_MESSAGES.NOT_FOUND));
        // }
    }
    catch (err) {
        yield (put(setError(err)));
        yield put(getTemplatesAPIDataFailure(CONFIG.DISPLAY_TEXT.ERROR_MESSAGES.NOT_FOUND));
    }
}

export default function* templatesAPISaga() {
    try {
        yield takeLatest(TEMPLATE_FILTERS.API_TEMPLATE_FILTERS_DATA, getTemplateData);

    } catch (error) {
        yield (put(setError(error)));
    }
}