import { EXTERNAL_NETWORK } from 'redux/constants';

//  People CCO actions
export const getExternalNetworkAPIData = () => ({
    type: EXTERNAL_NETWORK.API_EXTERNAL_NETWORK_DATA,
});

export const getExternalNetworkAPIDataPending = () => ({
    type: EXTERNAL_NETWORK.API_EXTERNAL_NETWORK_RESULTS_PENDING
});

export const getExternalNetworkAPIDataSuccess = (payload) => ({
    type: EXTERNAL_NETWORK.API_EXTERNAL_NETWORK_RESULTS_SUCCESS,
    payload
});

export const getExternalNetworkAPIDataFailure = (error) => ({
    type: EXTERNAL_NETWORK.API_EXTERNAL_NETWORK_RESULTS_FAILURE,
    payload: { error: error?.toString() }
});
