import React, { Fragment, useEffect, useState } from "react";
import Image from "components/shared/Image";
import { getUserProfilePictures } from "api/getUserProfilePictures";
import { Placeholder } from "semantic-ui-react";
import { ImageParaSkeleton } from 'components/shared/ImageParaSkeleton';
import NocaseTeamIcon from "assets/images/nocaseteam.svg";
import { Tooltip } from 'react-tooltip';
import { getRandomColor } from "utils/helpers/helpers";
import './RequestMembers.scss';

export const RequestMembers = ({ userdata }) => {
    const [usersData, setUserData] = useState(null);

    useEffect(() => {
        fetchUserDetails();
    }, [userdata]);

    const fetchUserDetails = async () => {
        let userProfilePictures = [];
        let hrids = [];
        userdata?.map((data, index) => {
            // if (data?.memberType !== 'requestor') {
            hrids?.push(data?.hrId);
            // }
        });
        let resp = await getUserProfilePictures(hrids);
        for (let i = 0; i < resp?.length; i++) {
            userProfilePictures?.push({
                picture: resp[i]?.picture?.includes('2b57bfed-513c-4f78-beca-f6a3dd6a75be.jpg') ? null : resp[i]?.picture,
                fullName: resp[i]?.firstName + '  ' + resp[i]?.lastName,
                nameInitials: resp[i]?.firstName.charAt(0) + resp[i]?.lastName.charAt(0),
                bgColor: getRandomColor()
            });
        }
        setUserData(userProfilePictures);
    }
    return (
        <div className="requestMembers__wrap">
            {
                usersData?.length > 0 && usersData !== null &&
                <>
                    <div className="user__group">
                        <>
                            {
                                usersData?.map((group, groupIndex) => (
                                    groupIndex < 3 && group ?
                                        <Fragment key={groupIndex}>
                                            {!group?.picture &&
                                                <span
                                                    style={{ background: group.bgColor }}
                                                    className={`req--img user--img custom--profile--pic ${groupIndex > 0 && 'imagesMarginLeft'}`}
                                                    data-tooltip-id={`my-tooltip-${groupIndex}`}
                                                    data-tooltip-content={group?.fullName}
                                                    data-tooltip-place="bottom">
                                                    {group.nameInitials}
                                                </span>
                                            }
                                            {group?.picture &&
                                                <Image
                                                    key={groupIndex}
                                                    src={group?.picture}
                                                    alt="user icon"
                                                    className={`req--img user--img ${groupIndex > 0 && 'imagesMarginLeft'}`}
                                                    data-tooltip-id={`my-tooltip-${groupIndex}`}
                                                    data-tooltip-content={group?.fullName}
                                                    data-tooltip-place="bottom"
                                                />}
                                            <Tooltip id={`my-tooltip-${groupIndex}`} />
                                        </Fragment>
                                        :
                                        <Placeholder key={groupIndex}>
                                            <Placeholder.Image square />
                                        </Placeholder>
                                ))}
                        </>
                        {
                            usersData?.length > 3 && (
                                <div className="user--count">+{usersData.length - 3}</div>
                            )
                        }
                    </div>
                </>
            }
            {
                usersData?.length === 0 &&
                <div className="noResults--found">
                    <Image src={NocaseTeamIcon} alt="NocaseTeamIcon" className="closedreq--icon" />
                    <span className="no--results">No Case Team Members Added</span>

                </div>
            }
            {
                usersData === null &&
                <div className="user__group imagesMarginLeftImg">
                    <ImageParaSkeleton showImage={true} imageCount={1} />
                </div>
            }
        </div>
    );
};
