import { disputeCallAPIData } from "redux/actions/disputeCall/disputeCall.saga";
import { DISPUTE_CALL } from "redux/constants";
export const name = "disputeCall";

// initial state
export const initialState = {
    loading: true,
    error: false,
    errorMessage: "",
    disputeCallData: '',
};

// reducer selectors
export const selectors = {
    getLoading: state => state[name].loading,
    getError: state => state[name].error,
    getErrorMessage: state => state[name].errorMessage,
    getDisputeCallsData: (state) => state[name]?.disputeCallData ? state[name]?.disputeCallData : "",
};

// reducer
export function reducer(state = initialState, action) {
    switch (action.type) {
        case DISPUTE_CALL.API_DISPUTE_CALL_RESULTS_FAILURE:
            return {
                ...state,
                loading: false,
                error: true,
                errorMessage: action.payload,
            };
        case DISPUTE_CALL.API_DISPUTE_CALL_RESULTS_PENDING:
            return {
                ...state,
                loading: action?.payload?.status?.length <= 0 ? true : false,
                error: false,
                errorMessage: '',
            };
        case DISPUTE_CALL.API_DISPUTE_CALL_RESULTS_SUCCESS:
            return {
                ...state,
                loading: false,
                disputeCallData: action?.payload?.status,
                errorMessage: action?.payload?.errors && action?.payload?.errors[0],
            };
        case DISPUTE_CALL.API_CLEAR_DISPUTE_CALL:
            return {
                ...state,
                disputeCallData: '',
            }
        default:
            return state;
    }
}