import { REQUEST_TYPE } from "redux/constants";
export const name = "requestType";

// initial state
export const initialState = {
    loading: true,
    error: false,
    errorMessage: "",
    requestTypesResults: [],
    noRecordFound: false,
};

// reducer selectors
export const selectors = {
    getLoading: state => state[name].loading,
    getError: state => state[name].error,
    getErrorMessage: state => state[name].errorMessage,
    getRequestTypesResults: (state) => state[name]?.requestTypesResults ? state[name]?.requestTypesResults : "",
    getNoRecordFound: state => state[name].noRecordFound,
};

// reducer
export function reducer(state = initialState, action) {
    switch (action.type) {
        case REQUEST_TYPE.API_REQUEST_TYPES_RESULTS_FAILURE:
            return {
                ...state,
                loading: false,
                error: true,
                errorMessage: action.payload
            };
        case REQUEST_TYPE.API_REQUEST_TYPES_RESULTS_PENDING:
            return {
                ...state,
                loading: true,
                noRecordFound: false,
                error: false,
                errorMessage: ''
            };
        case REQUEST_TYPE.API_REQUEST_TYPES_RESULTS_SUCCESS:
            return {
                ...state,
                loading: false,
                noRecordFound: action.payload?.length <= 0,
                requestTypesResults: action.payload
            };
        default:
            return state;
    }
}