import { put, call, takeLatest } from 'redux-saga/effects';
// Config
import CONFIG from "config";
import { RENAME_FILE } from "redux/constants";
// Axios Util
import { doAxiosRequest } from "config-axios";
// Actions
import {
    getRenameFileAPIDataPending,
    getRenameFileAPIDataFailure,
    getRenameFileAPIDataSuccess
} from "./renameAttachment.actions";

import { setError } from '../error/error.actions';

export function* renameFileRequestData(requestData) {
    const data = requestData.payload ? requestData.payload : null;
    if (data?.folder) {
        try {
            const axiosConfig = {
                method: 'PUT',
                endpoint: `${CONFIG.API_URL.RENAME_FILE}?folderName=upload/${data.folder}&oldFileName=${data.file}&newFileName=${data.newFileName}`,
                headers: {
                    'x-api-key': CONFIG.BE_X_API_KEY,
                    'content-type': CONFIG.HTTP_CONTENT_TYPES.json,
                },
            };
            yield put(getRenameFileAPIDataPending());
            const response = yield call(doAxiosRequest, axiosConfig);
            if (response) {
                yield put(getRenameFileAPIDataSuccess(response));
            }
            else {
                yield put(getRenameFileAPIDataFailure(CONFIG.DISPLAY_TEXT.ERROR_MESSAGES.NOT_FOUND));
            }
        }
        catch (err) {
            yield (put(setError(err)));
            yield put(getRenameFileAPIDataFailure(CONFIG.DISPLAY_TEXT.ERROR_MESSAGES.NOT_FOUND));
        }
    }
}

export default function* renameFileRequestAPISaga() {
    try {
        yield takeLatest(RENAME_FILE.API_RENAME_FILE, renameFileRequestData);

    } catch (error) {
        yield (put(setError(error)));
    }
}