import { PEOPLE_DATA } from "redux/constants";
export const name = "peopleresults";

// initial state
export const initialState = {
  loading: true,
  error: false,
  errorMessage: "",
  peopleResults: [],
  noRecordFound: false,
};

// reducer selectors
export const selectors = {
  getLoading: state => state[name].loading,
  getError: state => state[name].error,
  getErrorMessage: state => state[name].errorMessage,
  getPeopleResults: (state) => state[name].peopleResults ? state[name].peopleResults : [],
  getNoRecordFound: state => state[name].noRecordFound,
};

// reducer
export function reducer(state = initialState, action) {
  switch (action.type) {
    case PEOPLE_DATA.API_PEOPLE_RESULTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.payload
      };
    case PEOPLE_DATA.API_PEOPLE_RESULTS_PENDING:
      return {
        ...state,
        loading: true,
        noRecordFound: false,
        error: false,
        errorMessage: ''
      };
    case PEOPLE_DATA.API_PEOPLE_RESULTS_SUCCESS:
      return {
        ...state,
        loading: false,
        noRecordFound: [action.payload]?.length <= 0,
        peopleResults: [action.payload]
      };
    case PEOPLE_DATA.CLEAR_PEOPLE_RESULTS: // New action type to clear results
      return {
        ...state,
        peopleResults: [],
      };
    default:
      return state;
  }
}