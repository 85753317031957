import { put, call, takeLatest, takeEvery } from 'redux-saga/effects';
import CONFIG from "config";
import { EMP_DESIGNATION } from "redux/constants";
import {
    getEmployeeDesignationDataFailure,
    getEmployeeDesignationDataSuccess
} from "./employeeDesignation.actions";

import * as utils from '@kdshared/okta-utils';
import { doAxiosRequest } from "config-axios";
import { setError } from '../error/error.actions';

const getQueryData = (hrid) => {
    return `query {
      employee(id: "${hrid}") {
          id
          preferredName
          firstName
          lastName
          picture
          email
          homeOffice
          businessTitle
          businessTitle
          hostOfficeLocation {
            id
            name
            city
            country
            countryCode
            timezone
        }
    phones{
            phoneNumber
            deviceType
            countryCode
            usage
        }
      }
  }
  `
}

const getMultipleQueryData = (hrid) => {
    const idList = hrid ? hrid.map(id => `"${id}"`).join(', ') : '';
    return `query {
      employees(ids: [${idList}]) {
          id
          preferredName
          firstName
          lastName
          picture
          email
          homeOffice
          businessTitle
          hostOfficeLocation {
            id
            name
            city
            country
            countryCode
            timezone
        }
    phones{
            phoneNumber
            deviceType
            countryCode
            usage
        }
      }
  }
  `
}

export function* getEmployeeDesignationData(action) {
    try {
        const axiosConfig = {
            method: 'POST',
            endpoint: CONFIG.API_URL.GET_PEOPLE,
            headers: {
                'x-api-key': CONFIG.PEOPLE_API_X_API_KEY,
                psid: utils.getPsId(),
                'content-type': CONFIG.HTTP_CONTENT_TYPES.json,
            },
            params: {
                query: typeof (action?.payload) === 'string' ? getQueryData(action?.payload) : getMultipleQueryData(action?.payload),
            },
        };
        const response = yield call(doAxiosRequest, axiosConfig);

        if (response) {
            if (typeof (action?.payload) === 'string') {
                yield put(getEmployeeDesignationDataSuccess(response.data.employee?.businessTitle));
            }
            else {
                yield put(getEmployeeDesignationDataSuccess(response.data.employees[0]?.businessTitle));
            }
        }
        else {
            yield put(getEmployeeDesignationDataFailure(CONFIG.ERROR_MESSAGES.NOT_FOUND));
        }
    }
    catch (err) {
        yield (put(setError(err)));
        yield put(getEmployeeDesignationDataFailure(''));
    }
}

export default function* employeeDesignationDataAPISaga() {
    try {
        yield takeEvery(EMP_DESIGNATION.API_EMP_DESIGNATION, getEmployeeDesignationData);

    } catch (error) {
        yield (put(setError(error)));
    }
}