import { put, call, takeLatest } from 'redux-saga/effects';
// Config
import CONFIG from "config";
import { CHARGE_CODE_DETAILS } from "redux/constants";
// Axios Util
import { doAxiosRequest } from "config-axios";
import { moduleFailure } from "redux/global.action.js";
// Actions
import {
    getChargeCodeDetailsAPIDataFailure,
    getChargeCodeDetailsAPIDataPending,
    getChargeCodeDetailsAPIDataSuccess
} from "./chargeCodeDetails.actions";

import { setError } from '../error/error.actions';


export function* getChargeCodeDetailsAPIData(action) {
    const { API_URL: { GET_CHARGE_CODES_DETAILS } } = CONFIG;
    const paramURL = GET_CHARGE_CODES_DETAILS(action?.payload);
    try {
        const axiosConfig = {
            method: 'GET',
            endpoint: paramURL,
            headers: {
                'x-api-key': CONFIG.BE_X_API_KEY,
                'content-type': CONFIG.HTTP_CONTENT_TYPES.json,
            },
        };
        yield put(getChargeCodeDetailsAPIDataPending());
        const response = yield call(doAxiosRequest, axiosConfig);
        if (response) {
            yield put(getChargeCodeDetailsAPIDataSuccess(response?.data));
        }
        // else {
        //     yield put(getChargeCodeDetailsAPIDataFailure(CONFIG.DISPLAY_TEXT.ERROR_MESSAGES.NOT_FOUND));
        // }
    }
    catch (err) {
        yield (put(setError(err)));
        yield put(getChargeCodeDetailsAPIDataFailure(CONFIG.DISPLAY_TEXT.ERROR_MESSAGES.NOT_FOUND));
    }
}

export default function* chargeCodeDetailsAPISaga() {
    try {
        yield takeLatest(CHARGE_CODE_DETAILS.API_CHARGE_CODE_DETAILS_DATA, getChargeCodeDetailsAPIData);

    } catch (error) {
        yield (put(setError(error)));
    }
}