import { APPROVE_TEMPLATES } from 'redux/constants';

//  People CCO actions
export const getApproveTemplatesAPIData = (data) => ({
    type: APPROVE_TEMPLATES.API_APPROVE_TEMPLATES_DATA,
    payload: data
});

export const getApproveTemplatesAPIDataPending = () => ({
    type: APPROVE_TEMPLATES.API_APPROVE_TEMPLATES_RESULTS_PENDING
});

export const getApproveTemplatesAPIDataSuccess = (payload) => ({
    type: APPROVE_TEMPLATES.API_APPROVE_TEMPLATES_RESULTS_SUCCESS,
    payload
});

export const getApproveTemplatesAPIDataFailure = (error) => ({
    type: APPROVE_TEMPLATES.API_APPROVE_TEMPLATES_RESULTS_FAILURE,
    payload: { error: error?.toString() }
});

export const clearApproveTemplatesAPIData = () => ({
    type: APPROVE_TEMPLATES.CLEAR_APPROVE_TEMPLATES,
  });
