import { REQUEST_TYPE } from 'redux/constants';

//  People CCO actions
export const getRequestTypeAPIData = () => ({
    type: REQUEST_TYPE.API_REQUEST_TYPES_DATA,
});

export const getRequestTypeAPIDataPending = () => ({
    type: REQUEST_TYPE.API_REQUEST_TYPES_RESULTS_PENDING
});

export const getRequestTypeAPIDataSuccess = (payload) => ({
    type: REQUEST_TYPE.API_REQUEST_TYPES_RESULTS_SUCCESS,
    payload
});

export const getRequestTypeAPIDataFailure = (error) => ({
    type: REQUEST_TYPE.API_REQUEST_TYPES_RESULTS_FAILURE,
    payload: { error: error?.toString() }
});
