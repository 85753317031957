import { put, call, takeLatest } from 'redux-saga/effects';
// Config
import CONFIG from "config";
import { SHARE_VIA_EMAIL } from "redux/constants";
// Axios Util
import { doAxiosRequest } from "config-axios";
import { moduleFailure } from "redux/global.action.js";
// Actions
import {
    getShareViaEmailAPIDataPending,
    getShareViaEmailAPIDataFailure,
    getShareViaEmailAPIDataSuccess
} from "./shareViaEmail.actions";

import { setError } from '../error/error.actions';


export function* getshareViaEmailAPIData(action) {
    try {
        const axiosConfig = {
            method: 'put',
            endpoint: CONFIG.API_URL.SHARE_VIA_APPROVAL,
            headers: {
                'x-api-key': CONFIG.BE_X_API_KEY,
                'content-type': CONFIG.HTTP_CONTENT_TYPES.json
            },
            data: JSON.stringify(action?.payload)
        };
        yield put(getShareViaEmailAPIDataPending());
        const response = yield call(doAxiosRequest, axiosConfig);
        if (response) {
            yield put(getShareViaEmailAPIDataSuccess(response));
        }
        else {
            yield put(getShareViaEmailAPIDataFailure(CONFIG.DISPLAY_TEXT.ERROR_MESSAGES.NOT_FOUND));
        }
    }
    catch (err) {
        yield (put(setError(err)));
        yield put(getShareViaEmailAPIDataFailure(CONFIG.DISPLAY_TEXT.ERROR_MESSAGES.NOT_FOUND));
    }
}

export default function* shareViaEmailAPISaga() {
    try {
        yield takeLatest(SHARE_VIA_EMAIL.API_SHARE_VIA_EMAIL_DATA, getshareViaEmailAPIData);

    } catch (error) {
        yield (put(setError(error)));
    }
}