import React from "react";
import PropTypes from "prop-types";
import ReactModal from "react-modal";

ReactModal.setAppElement("body");

export const Modal = ({ isOpen, children, className, onRequestClose, ...others }) => {
  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      overlayClassName="ReactModal__Overlay"
      className={`ReactModal__Content  ${className}`}
      {...others}
      shouldCloseOnOverlayClick={true}
    >
      {children}
    </ReactModal>
  );
};

Modal.propTypes = {
  isOpen: PropTypes.bool,
  className: PropTypes.string,
  onRequestClose: PropTypes.func,
};

Modal.defaultProps = {
  isOpen: false,
  className: "",
};
