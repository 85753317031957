import { CHARGE_CODE } from 'redux/constants';

//  People CCO actions
export const getChargeCodeAPIData = (searchString, nextPageToken) => ({
    type: CHARGE_CODE.API_CHARGE_CODE_DATA,
    payload: {
        searchString: searchString || '',
        nextPageToken
    }
});

export const getChargeCodeAPIDataPending = () => ({
    type: CHARGE_CODE.API_CHARGE_CODE_RESULTS_PENDING
});

export const getChargeCodeAPIDataSuccess = (payload) => ({
    type: CHARGE_CODE.API_CHARGE_CODE_RESULTS_SUCCESS,
    payload
});

export const getChargeCodeAPIDataFailure = (error) => ({
    type: CHARGE_CODE.API_CHARGE_CODE_RESULTS_FAILURE,
    payload: { error: error?.toString() }
});
