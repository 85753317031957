import React, { Fragment, PureComponent } from "react";
import { Container } from "react-bootstrap";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router";
import CONFIG from "config";
import { ErrorBoundary } from "containers/ErrorBoundary/ErrorBoundary";
// import { getPeopleResult } from 'redux/actions/peopleresults/peopleresults_actions.js';
import { selectors as globalErrorSelector } from "redux/reducers/error/error_reducer";
import "./Layout.scss";
import { setLoggedInUserData } from "redux/global.action";
import { Header } from "components/Header";
import { getUserId } from "utils/auth/auth";
import { ErrorScreen } from "components/ErrorScreen";
import { AppFooter } from "components/AppFooter";

export class LayoutContainer extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      isError: false
    }
  }
  walkMeInitialize = () => {
    if (window) {
      const { API_URL: { WALKME_SCRIPT_URL } } = CONFIG;
      const walkme = document.createElement('script');
      walkme.type = 'text/javascript';
      walkme.async = true;
      walkme.src = WALKME_SCRIPT_URL;
      const s = document.getElementsByTagName('script')[0];
      if (!!s) {
        s.parentNode.insertBefore(walkme, s);
      }
      window._walkmeConfig = { smartLoad: true };
    }
  }

  goHome = () => {
    const { history } = this.props;
    history.push(CONFIG.UI_URL.HOME);
  };

  componentDidUpdate(prevProps) {
    if (prevProps.errorData !== this.props.errorData) {
      this.setState({ isError: !!this.props.errorData });
    }
  }

  render = () => {
    const {
      children,
      layoutClass,
      isBodyScrollable,
      errorData
    } = this.props;


    return (
      <div className={`layout layout__page ${!isBodyScrollable ? 'layout--unscrollable' : ''}`}>
        <Header isError={this.state.isError} />
        <div className={`main`}>
          {
            this.state.isError === true ?
              <ErrorScreen />
              :
              <Fragment>
                <ErrorBoundary>
                  <Container
                    className={`layout__container layout__fix ${layoutClass}`}
                    fluid
                  >
                    {children}
                  </Container>
                </ErrorBoundary>
              </Fragment>
          }

        </div >
        {/* <AcknowledgementModal /> */}
        <AppFooter />
      </div >
    );
  };
}

LayoutContainer.propTypes = {
  children: PropTypes.node,
  layoutClass: PropTypes.string
};

LayoutContainer.defaultProps = {
  layoutClass: "",
};

const mapStateToProps = (state) => ({
  errorData: globalErrorSelector.getError(state)
});

const mapDispatchToProps = (dispatch) => ({
  // peopleResultsActions: bindActionCreators({ ...getPeopleResult }, dispatch),
  setLoggedInUserData: (data) => dispatch(setLoggedInUserData(data))
});

export const Layout = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(LayoutContainer)
);